import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import { loginApiSlice } from '../../Redux/Slices/Login/Login';
import { useNavigate } from 'react-router-dom';

const intialState = {
    phoneNumber: '',
    rememberMe: false,
    errors: {}
}

const Login = () => {

    const [cState, updateCState] = useState(intialState);

    const {
        phoneNumber,
        rememberMe,
        errors
    } = cState

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const handleInputChange = (e) => {

    //     const { name, value, checked } = e.target

    //     if (name === 'rememberMe') {
    //         updateCState({
    //             ...cState, [name]: checked
    //         })
    //     }
    //     else {
    //         updateCState({
    //             ...cState, [name]: value
    //         })
    //     }
    // }

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'rememberMe') {
            updateCState({
                ...cState,
                [name]: checked
            });
        } else {
            // If the field is for a number and should not exceed 10 digits
            if (name === 'phoneNumber' && value.length > 10) {
                return; // Prevent updating state if the value exceeds 10 digits
            }

            updateCState({
                ...cState,
                [name]: value
            });
        }
    };


    const handlePhoneNumber = (e) => {
        const { name, value } = e.target
        if (name === 'phoneNumber' && Number(phoneNumber) <= 9999999999)
            updateCState({
                ...cState, [name]: value
            })
    }

    const handleValidation = () => {
        let isValid = true;
        let error = {}
        if (!phoneNumber) {
            error.phoneNumber = 'Phone number is required'
            isValid = false
        } else if (phoneNumber.length < 10) {
            error.phoneNumber = 'Enter a valid 10-digit phone number.'
            isValid = false
        }
        if (!rememberMe) {
            error.rememberMe = 'Please accept terms and condition'
            isValid = false
        }
        updateCState({
            ...cState,
            errors: error
        })
        return isValid
    }
    console.log("cState", cState);

    const handlelogin = (e) => {
        e.preventDefault()
        const data = {
            contact: Number(phoneNumber)
        }
        if (handleValidation()) {
            console.log('phone number', phoneNumber, 'rememberMe', rememberMe)
            dispatch(loginApiSlice(data)).then((res) => {
                console.log('before', res?.payload?.status)
                if (res?.payload?.status === 500) {
                    console.log('in error if')
                    toast.error('User with this contact number does not exist', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    return
                }

                if (res?.payload?.status == 200) {
                    console.log('center', res)
                    navigate('/verification', { state: { otp: res?.payload?.data?.otp, contact: res?.payload?.data?.contact } });
                }
                console.log('after', res)
            })
        }
    }

    return (
        <div className="LoginArea">
            <div className="LoginBox">
                <figure>
                    <img src={require('../../Assets/images/Logo.png')} />
                </figure>
                <form onSubmit={handlelogin}>
                    <h3>Login/Sign Up</h3>
                    <p>Enter {/* your email address or */} mobile number to get OTP code</p>
                    <div className="form-group">
                        <label>
                            {/* Email Address or  */}
                            Mobile No.</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Mobile No."
                            name='phoneNumber'
                            value={phoneNumber}
                            onChange={handleInputChange}
                        />
                        <span className='text-danger'>{errors?.phoneNumber}</span>
                    </div>
                    <div className="form-group">
                        <div className="Checkboxs">
                            <label className="CheckBox">
                                I agree to Terms &amp; Conditions
                                <input type="checkbox" checked={rememberMe === true ? true : false} onChange={handleInputChange} name='rememberMe' />
                                <span className="checkmark" />
                                <br />
                                <span style={{ color: "red" }}>{errors.rememberMe}</span>
                            </label>
                        </div>
                    </div>
                    <button className="Button" type='submit'>
                        Login
                    </button>
                    <div className="LogUsingOther">
                        <h2>Or sign up with</h2>
                        <div className="LoginSocialIcons">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">

                                        <span>
                                            <img src={require('../../Assets/images/google.png')} />
                                        </span>
                                        Google
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">

                                        <span>
                                            <img src={require('../../Assets/images/facebook.png')} />
                                        </span>
                                        Facebook
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;