import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile, servicesList } from '../../../Redux/Slices/Profile/profile'
import { toast } from 'react-toastify'
import { ProfileUpdate } from '../../../Redux/Slices/ProfileUpdate/ProfileUpdate'
const initialState = {
    selectedServices: []
}
const Services = () => {
    const [iState, updateState] = useState(initialState)
    const { selectedServices } = iState
    const dispatch = useDispatch()
    const { profileData, servicesList: myServicesList } = useSelector((state) => state?.profileData)
    console.log('servicesList', myServicesList)

    const handleChange = (e, item) => {
        console.log('item', item, e.target.checked)
        if (e.target.checked) {
            updateState({
                ...iState,
                selectedServices: [...iState.selectedServices, item?._id]
            })
        }
        else {
            updateState({
                ...iState,
                selectedServices: selectedServices.filter((data) => item?._id != data)
            })
        }
    }
    console.log('selected services list', selectedServices)

    const handleSaveSelectedServices = (e) => {
        e.preventDefault()
        try {
            const data = {
                services: selectedServices
            }
            if (selectedServices.length) {
                dispatch(ProfileUpdate(data)).then((res) => {
                    console.log('before', res);
                    if (res?.payload?.status === 200) {
                        dispatch(getProfile())
                        toast.success('Services Add Succssfully', {
                            position: "top-right",
                            autoClose: '1000'
                        })
                    }
                })
            } else {
                toast.error('Please Add Services', {
                    position: "top-right",
                    autoClose: '1000'
                })
            }

        } catch (error) {

        }
    }
    useEffect(() => {
        dispatch(servicesList())
        dispatch(getProfile())
    }, [])
    return (
        <div>
            <div className="tab-pane">
                <h2
                >
                    Please choose from the following services you will provide:
                </h2>

                <div className="StaticArea">
                    <div>
                        <button onClick={handleSaveSelectedServices} style={{ float: 'right', padding: '5px 7px', borderRadius: '10px', border: 'none', background: 'rgba(255, 144, 0, 1)', color: 'white', width: '60px', height: '35px', fontWeight: '600', fontSize: "medium" }}>Save</button>
                        <Accordion defaultActiveKey='0'>
                            {
                                myServicesList?.data?.result?.length > 0 ?
                                    myServicesList?.data?.result?.map((item, index) => {
                                        return (
                                            <>
                                                <input type='checkbox' onChange={(e) => handleChange(e, item)} checked={selectedServices.includes(item?._id)} style={{
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    top: '49px',
                                                    left: '96%',
                                                    width: '20px',
                                                    height: '34px',
                                                }} />
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header>

                                                        <h4 dangerouslySetInnerHTML={{ __html: item?.serviceName }} ></h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p dangerouslySetInnerHTML={{ __html: item?.description }} ></p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>
                                        )
                                    }) : 'No Services found'
                            }
                        </Accordion>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Services