import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const getProfile = createAsyncThunk(
    "getProfile/getProfile",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `my-profile`,
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
export const servicesList = createAsyncThunk(
    "getServicesList/getServicesList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `service-list`,
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
const initialState = {
    profileData: {},
    servicesList: {},
    loading: false,
    error: null,
}

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.profileData = action.payload;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(servicesList.pending, (state) => {
                state.loading = true;
            })
            .addCase(servicesList.fulfilled, (state, action) => {
                state.loading = false;
                state.servicesList = action.payload;
            })
            .addCase(servicesList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
})

export default profileSlice.reducer;