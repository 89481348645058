import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const PersonalDetail = () => {
    const { data } = useSelector((state) => state?.profileData?.profileData)
    console.log('profileData', data)
    return (
        <div className="tab-pane">
            <div className="Small-Wrapper">
                <fieldset disabled="">
                    <div className="CommonForm">
                        <form>
                            <div className="UserPlanBox">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Full Name"
                                                value={data?.fullName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email ID</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Email ID"
                                                value={data?.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contact Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Contact Number"
                                                value={data?.contact}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>DOB</label>
                                            <input type="date" className="form-control" value={data?.dob} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Marital Status</label>
                                            <select className="form-control" value={data?.maritalStatus}>
                                                <option>--select--</option>
                                                <option value='married'>married</option>
                                                <option value='unmarried'>Unmarried</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <ul className="UserPlan text-left">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Male
                                                        <input type="radio" name="plan" checked={data?.gender === 'male' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Female
                                                        <input type="radio" name="plan" checked={data?.gender === 'female' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Others
                                                        <input type="radio" name="plan" checked={data?.gender === 'other' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Address Line 1</label>
                                            <input type="text" className="form-control" value={data?.address1} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Address Line 2</label>
                                            <input type="text" className="form-control" value={data?.address2} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>City</label>
                                            <select className="form-control">
                                                <option>{data?.city}</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>State</label>
                                            <select className="form-control">
                                                <option>{data?.state}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <select className="form-control">
                                                <option>{data?.country}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>Pincode</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Pincode"
                                                value={data?.pincode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </fieldset>
            </div>
        </div>

    )
}

export default PersonalDetail