import logo from './logo.svg';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Bounce } from "react-toastify";
import { HashRouter, Route, Routes } from 'react-router-dom';

import Login from './app/auth/Login';
import Verification from './app/auth/Verification';
import StepDetails from './app/auth/StepDetails';
import Personal from './app/auth/Personal';
import Skills from './app/auth/Skills';
import FillOthers from './app/auth/FillOthers';
import Document from './app/auth/Document';

import Dashboard from './app/main/dashboard/Dashboard';
import Profile from './app/main/profile/Profile';
import BankDetails from './app/main/bankdetails/BankDetails';
import Appointment from './app/main/appointment/Appointment';
import SetAvailability from './app/main/setavailability/SetAvailability';
import Wallet from './app/main/wallet/Wallet';
import Support from './app/main/support/Support';
import Faq from './app/main/faq/Faq';
import AddSupport from './app/main/support/AddSupport';
import SupportDetail from './app/main/support/SupportDetail';
import SupportNotes from './app/main/support/SupportNotes';
import GuideLines from './app/main/guideline/Guideline';
import CreateAccount from './app/auth/CreateAccount';
import Call from './app/main/appointment/Call';
import GenrateReport from './app/main/appointment/GenrateReport';
import AppointmentCompletedDetail from './app/main/appointment/AppointmentCompletedDetail';
import UserDetail from './CustomComponents/UserDetail';

function App() {
  return (

    <div>
      <HashRouter>
        <Routes>

          <Route exact path="/" element={<Login />} />
          <Route exact path="/createAccount" element={<CreateAccount />} />
          <Route exact path="/verification" element={<Verification />} />
          <Route exact path="/step-details" element={<StepDetails />} />
          <Route exact path="/personal" element={<Personal />} />
          <Route exact path="/skills" element={<Skills />} />
          <Route exact path="/fill-others" element={<FillOthers />} />
          <Route exact path="/document" element={<Document />} />


          <Route exact path="/dashboard" element={<Dashboard />} />

          <Route exact path='/profile' element={<Profile />} />

          <Route exact path='/bank-details' element={<BankDetails />} />

          <Route exact path='/appointment/:type' element={<Appointment />} />
          <Route exact path='/appointmentCompletedDetail' element={<AppointmentCompletedDetail />} />
          <Route exact path='/userDetail' element={<UserDetail />} />
          <Route exact path='/set-availability' element={<SetAvailability />} />
          <Route exact path='/wallet' element={<Wallet />} />
          <Route exact path='/support' element={<Support />} />
          <Route exact path='/addSupport' element={<AddSupport />} />
          <Route exact path='/supportDetail' element={<SupportDetail />} />
          <Route exact path='/supportNotes' element={<SupportNotes />} />
          <Route exact path='/faq' element={<Faq />} />
          <Route exact path='/guideLines' element={<GuideLines />} />
          <Route exact path='/call' element={<Call />} />
          <Route exact path='/genrateReport' element={<GenrateReport />} />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
