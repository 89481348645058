import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../app/main/header/Header'
import SideNav from '../app/main/sidenav/Sidenav'

const UserDetail = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    console.log('UserDetail', state)
    return (
        <div>
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Members Details</h4>
                        <div className="BackArrow m-0">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="BuildingArea">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <a
                                        href="javascript:void(0);"
                                        className="View"
                                        data-toggle="modal"
                                        data-target="#ViewKundli"
                                    >
                                        View Kundli
                                    </a>
                                    <h4>Member 1 Details</h4>
                                    <p>
                                        <label>Full Name</label>
                                        <span>{state?.basicDetails?.profile1?.name}</span>
                                    </p>
                                    <p>
                                        <label>Date of Birth</label>
                                        <span>{state?.basicDetails?.profile1?.dob}</span>
                                    </p>
                                    <p>
                                        <label>Time of Birth</label>
                                        <span>{state?.basicDetails?.profile1?.time}</span>
                                    </p>
                                    <p>
                                        <label>Location</label>
                                        <span>
                                            {state?.basicDetails?.profile1?.birthPlace}{" "}
                                            {/* <a href="javascript:void(0);">
                                                    <i className="fa-solid fa-location-dot" />
                                                </a> */}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {
                                state?.serviceData.serviceName == 'marriage compatibility' ? <div className="col-sm-6">
                                    <div className="BuildingPackage">
                                        <a
                                            className="View"
                                            data-toggle="modal"
                                            data-target="#ViewKundli"
                                        >
                                            View Kundli
                                        </a>
                                        <h4>Member 2 Details</h4>
                                        <p>
                                            <label>Full Name</label>
                                            <span>{state?.basicDetails?.profile2?.name}</span>
                                        </p>
                                        <p>
                                            <label>Date of Birth</label>
                                            <span>{state?.basicDetails?.profile2?.dob}</span>
                                        </p>
                                        <p>
                                            <label>Time of Birth</label>
                                            <span>{state?.basicDetails?.profile2?.time}</span>
                                        </p>
                                        <p>
                                            <label>Location</label>
                                            <span>
                                                {state?.basicDetails?.profile2?.birthPlace}{" "}
                                                {/* <a href="javascript:void(0);">
                                                    <i className="fa-solid fa-location-dot" />
                                                </a> */}
                                            </span>
                                        </p>
                                    </div>
                                </div> : null
                            }

                            <div className="col-sm-12">
                                <div className="BuildingPackage">
                                    <h4>View Questions</h4>
                                    {
                                        state?.basicDetails?.question?.map((item) => {
                                            return (

                                                <p>
                                                    <label>Question</label>
                                                    <span>
                                                        {
                                                            item
                                                        }
                                                    </span>
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SideNav />
        </div>


    )
}

export default UserDetail