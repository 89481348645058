import React, { useEffect, useState } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bankListApiSlice } from "../../../Redux/Slices/BankDetail/bank";
import { walletList, withdrawAmount } from "../../../Redux/Slices/Wallet/WithdrawAmount";
import { toast } from "react-toastify";
import moment from "moment/moment";

const initialState = {
    selectBankModalOpen: false,
    selectedBank: null,
    amountModal: false,
    amount: 0
}
const Wallet = () => {
    const [iState, updateState] = useState(initialState)
    const { selectedBank, selectBankModalOpen, amountModal, amount } = iState
    const dispatch = useDispatch()
    const { bankData } = useSelector((state) => state?.bankData)
    const { walletData } = useSelector((state) => state?.walletData)
    console.log('bank list:', selectedBank, 'walletData', walletData)
    const handleWithdraw = (e) => {
        e.preventDefault()
        const data = {
            bankId: selectedBank,
            amount: amount * 100
        }
        dispatch(withdrawAmount(data)).then((res) => {
            console.log('Response of deducted amount:', res)
            if (res?.payload?.status === 200) {
                toast.success('Amount Withdrawed successfully', {
                    position: "top-right",
                    autoClose: '1000'
                })
                updateState(initialState)
                dispatch(walletList())
            } else {
                toast.error(res?.payload?.message, {
                    position: "top-right",
                    autoClose: '1000'
                })
            }
        })
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        console.log('name:', name, 'value:', value)
        updateState({ ...iState, [name]: value })
    }
    console.log('bank data:', bankData)
    const handleSelectBank = (item) => {
        updateState({ ...iState, selectBankModalOpen: true, amountModal: false })
    }
    useEffect(() => {
        const data = {
            search: "",
            startDate: "",
            endDate: "",
            timeFrame: "",
            page: '1'
        }
        dispatch(bankListApiSlice(data))
        dispatch(walletList())
    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Wallet</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="WithdrawBox">
                                <aside>
                                    <span className="Icon">
                                        <img src={require('../../../Assets/images/CreditCard.png')} />
                                    </span>
                                    <p>Total Earning (in Rs.)</p>
                                    <h2> {walletData?.data?.totalWalletAmount}</h2>
                                </aside>
                                <a

                                    className="WithdarwBtn"
                                    onClick={handleSelectBank}
                                >
                                    Withdraw
                                </a>
                                <span>
                                    (Withdrawal Policy: Lorem Ipsum jhuhfde loewnk lkipz nagrui)
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/appointment-icon-2.png')} />
                                        </span>
                                        <h2>Total Completed Bookings</h2>
                                        <article>
                                            <h3>{walletData?.data?.totalCompletedBookings}</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/total-amount.png')} />
                                        </span>
                                        <h2>Total Amount (in Rs.)</h2>
                                        <article>
                                            <h3>25</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/total-deductions.png')} />
                                        </span>
                                        <h2>Total Commission Deductions (in Rs.)</h2>
                                        <article>
                                            <h3>25</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/total-withdrawn.png')} />
                                        </span>
                                        <h2>
                                            Total Amount Withdrawn <br /> (in Rs.)
                                        </h2>
                                        <article>
                                            <h3>{walletData?.data?.totalWithdraw}</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row"></div>
                    <div className="TitleBox">
                        <h4 className="Title">Transaction Details</h4>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Transaction ID</th>
                                    <th>Transaction Date &amp; Time</th>
                                    <th>Status</th>
                                    <th>Amount (in Rs.)</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    walletData?.data?.bookings?.length > 0 ? walletData?.data?.bookings?.map((item, index) => {
                                        return (<tr>
                                            <td>{index + 1}</td>
                                            <td>{item?.transactionId}</td>
                                            <td>{moment(item?.createdAt).format('DD/MM/YYYY hh:mm a')}</td>
                                            <td>
                                                <span className={item?.transactionType === 'add' ? 'Green' : 'Red'}>{item?.transactionType === 'add' ? 'Credited' : 'Debited'}</span>
                                            </td>
                                            <td>{item?.amount}</td>
                                            <td>
                                                <span className="Orange">Download Payment Invoice</span>
                                            </td>
                                        </tr>)
                                    }) : 'No data available'
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal
                show={selectBankModalOpen}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <div className="Category">
                            <a
                                className="CloseModal Position1"
                                onClick={() => updateState({ ...iState, selectBankModalOpen: false })}
                            >
                                ×
                            </a>
                            <h4>&nbsp;</h4>
                            <div className="CongratsArea">
                                <p>Please Select Bank Account to Withdraw Money</p>
                                <select className="form-control" name="selectedBank" onChange={handleChange}>
                                    <option value="">Select Bank Account</option>
                                    {
                                        bankData?.data?.result?.map((item) => {
                                            return (<option value={item?._id}>{item?.bankName}</option>)
                                        })
                                    }
                                </select>
                                <div className="Buttons">
                                    <a
                                        className="Button FolderPermissionId ml-0"
                                        onClick={() => updateState({ ...iState, selectBankModalOpen: false, amountModal: true })}
                                    >
                                        Contine
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal
                show={amountModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <div className="Category">
                            <a className="CloseModal" data-dismiss="modal" onClick={() => updateState({ ...iState, amount: 0, amountModal: false })}>
                                ×
                            </a>
                            <h3>Enter Amount</h3>
                            <div className="ModalFormArea">
                                <form>
                                    <div className="form-group">
                                        <label>Enter Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Value"
                                            name="amount"
                                            value={amount}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="Buttons">
                                        <button
                                            type="submit"
                                            className="Button"
                                            onClick={handleWithdraw}
                                        >
                                            Withdraw
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default Wallet;