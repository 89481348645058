import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";
import { Base64 } from 'js-base64';
import { credAndUrl } from "../../../Config/config";
import axios from "axios";

export const getKundliData = createAsyncThunk(
    "getKundliData/postgetKundliData",
    async (payload) => {
        console.log("payload===>", payload, 'token', Base64.encode(credAndUrl.astrologyApiUser + ':' + credAndUrl.astrologyApiPassword), 'user', credAndUrl.astrologyApiUser, 'api key', credAndUrl.astrologyApiPassword, 'credAndUrl', credAndUrl);
        try {
            const response = await axios.post(
                `https://pdf.astrologyapi.com/v1/basic_horoscope_pdf`,
                payload, {
                headers: {
                    'Authorization': 'Basic ' + Base64.encode(credAndUrl.astrologyApiUser + ':' + credAndUrl.astrologyApiPassword)
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);