import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const AppointmentUpcoming = () => {
    const { appointmentDataList } = useSelector((state) => state?.appointmentData)
    console.log('upcominglist', appointmentDataList)
    const navigate = useNavigate()

    const appointmentIsActive = (appointmentTime, appointmentDate) => {
        console.log(appointmentTime, appointmentDate)
        const today = new Date()
        const appointmentDateArr = appointmentDate?.split('/')
        const appointmentTimeArr = appointmentTime?.split(':')
        console.log(appointmentDateArr, appointmentTimeArr)
        console.log(appointmentTimeArr?.[0], today.getMinutes(), appointmentTimeArr?.[0] <= today.getMinutes())
        console.log(today.getDate(), today.getMonth(), today.getFullYear(), 'date', today.getHours(), today.getMinutes())
        if ((Number(appointmentDateArr?.[0]) === today?.getDate() && Number(appointmentDateArr?.[1]) === today?.getMonth() + 1 && Number(appointmentDateArr?.[2]) === today.getFullYear()) && appointmentTimeArr[0] <= today.getHours() && appointmentTimeArr[1] <= today.getMinutes()) {
            return 'link active'
        }
        return 'future pending'
    };
    const handleClick = (item) => {
        const status = appointmentIsActive(item?.slots?.startTime, item?.slots?.date, item?.duration)
        console.log('status: ', status)
        if (status === 'link active') {
            navigate('/call', { state: item })
        } else (
            toast.error('Call link is not active', {
                position: "top-center",
                autoClose: 2000,
            })
        )
    }
    const callBackGround = (item) => {
        const status = appointmentIsActive(item?.slots?.startTime, item?.slots?.date)
        console.log('status', status)
        if (status === 'link active') {
            return 'link active'
        }
        if (status === 'future pending') {
            return 'future pending'
        }
    }
    return (
        <div><div className="TitleBox justify-content-end m-0">
            <a
                href="javascript:void(0);"
                className="TitleLink"
                style={{ marginTop: "-85px" }}
            >
                Download CSV
            </a>
        </div>
            <div className="Small-Wrapper">
                <div className="FilterArea">
                    <div className="FilterLeft">
                        <div className="form-group">
                            <label>Search</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search here.."
                            />
                        </div>
                        <div className="form-group">
                            <label>From Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>To Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>&nbsp;</label>
                            <button className="Button">Apply</button>
                            <button className="Button Cancel">
                                <i className="fa fa-refresh" />
                            </button>
                        </div>
                    </div>
                    <div className="FilterRight">
                        <div className="form-group">
                            <label>Timeframe</label>
                            <select className="form-control">
                                <option>Select </option>
                                <option value="Today">Today</option>
                                <option value="Week">This Week</option>
                                <option value="Month">This Month</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="TableList">
                <table style={{ width: "120%" }}>
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Appointment ID</th>
                            <th>Service Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Call Type &amp; Duration</th>
                            <th>View User Details</th>
                            <th>
                                View Previous <br /> Consultation Details
                            </th>
                            <th>Reschedule/Cancel</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            appointmentDataList?.data?.result?.map((item, index) => {
                                return (<tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.bookingNo}</td>
                                    <td>{item?.serviceData?.serviceName}</td>
                                    <td>{item?.slots?.date}</td>
                                    <td>{item?.slots?.startTime}</td>
                                    <td>Online (({item?.callType}) {item?.duration} min)</td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <a href="upcoming-details.html" className="Blue ml-0">
                                                <i className="fa fa-eye" />
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <a
                                                href="previous-consultant-details.html"
                                                className="Blue ml-0"
                                            >
                                                <i className="fa fa-eye" />
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="NewActions justify-content-center">
                                            <a href="support-enquiry.html" className="Red">
                                                Reschedule
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="NewActions justify-content-center">
                                            <a className={callBackGround(item) === 'link active' ? 'Green' : 'Blue'} onClick={() => handleClick(item)}>
                                                Call
                                            </a>
                                        </div>
                                    </td>
                                </tr>)
                            })
                        }

                    </tbody>
                </table>
            </div></div>
    )
}

export default AppointmentUpcoming