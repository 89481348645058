import React, { useEffect, useState } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addBankDetailApiSlice, bankListApiSlice, deleteBankDetailApiSlice, editBankDetailApiSlice } from "../../../Redux/Slices/BankDetail/bank";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";

const initialState = {
    id: "",
    addModal: false,
    editModal: false,
    deleteModal: false,
    accountHolderName: "",
    bankName: "",
    branchName: "",
    accountNumber: "",
    ifscCode: "",
    errors: {},
    page: '1',
    search: "",
    startDate: "",
    endDate: "",
    timeFrame: "",
}
const BankDetails = () => {
    const [iState, updateState] = useState(initialState)
    const { addModal, editModal, accountHolderName, bankName, branchName, accountNumber, ifscCode, errors, id, deleteModal, page, startDate, endDate, search, timeFrame } = iState
    const dispatch = useDispatch()
    const [ActivePage, updateActivePage] = useState(1)
    const [serialNo, updateSerialNo] = useState(10);
    const { bankData, status } = useSelector((state) => state?.bankData)
    console.log("bankListData", bankData)

    const handleAddModal = () => {
        updateState({ ...iState, addModal: true })
    }

    const handleEditModal = (item) => {
        updateState({
            ...iState,
            editModal: true,
            accountHolderName: item.accountHolderName,
            bankName: item.bankName,
            branchName: item.branchName,
            accountNumber: item.accountNumber,
            ifscCode: item.ifscCode,
            id: item?._id
        })
    }

    const handleDeleteModal = (item) => {
        updateState({ ...iState, deleteModal: true, id: item?._id })
    }

    const handleAddClose = () => {
        updateState(initialState)
    }

    const handleChange = (e) => {
        updateState({ ...iState, [e.target.name]: e.target.value })
    }

    const handleValidation = () => {
        let error = {}
        let isValid = true;

        if (!accountHolderName) {
            error.accountHolderName = "Account Holder Name is required"
            isValid = false;
        }

        if (!bankName) {
            error.bankName = "Bank Name is required"
            isValid = false;
        }

        if (!branchName) {
            error.branchName = "Branch Name is required"
            isValid = false;
        }

        if (!accountNumber) {
            error.accountNumber = "Account Number is required"
            isValid = false;
        }

        if (!ifscCode) {
            error.ifscCode = "IFSC Code is required"
            isValid = false;
        }

        updateState({
            ...iState,
            errors: error
        })
        return isValid
    }

    const handleAddSubmit = async () => {
        try {
            console.log('handleValidation', handleValidation(), iState)
            if (handleValidation()) {
                const data = {
                    bankName,
                    branchName,
                    ifscCode,
                    accountNumber,
                    accountHolderName: accountHolderName
                }
                const addBankDetailResponse = await dispatch(addBankDetailApiSlice(data))
                console.log('addBankDetailResponse', addBankDetailResponse)
                if (addBankDetailResponse.payload.status === 200) {
                    toast.success('Bank Detail Added Successfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    updateState({ ...iState, bankName: "", branchName: "", ifscCode: "", accountNumber: "", bankHolderName: "", addModal: false })
                }
            }
        } catch (error) {

        }

    }

    const handleEditSubmit = async () => {
        try {
            console.log('handleValidation', handleValidation(), iState)
            if (handleValidation()) {
                const data = {
                    bankId: id,
                    bankName,
                    branchName,
                    ifscCode,
                    accountNumber,
                    accountHolderName: accountHolderName
                }
                const editBankDetailResponse = await dispatch(editBankDetailApiSlice(data))
                console.log('addBankDetailResponse', editBankDetailResponse)
                if (editBankDetailResponse.payload.status === 200) {
                    toast.success('Bank Detail Edited Successfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    // updateState({ ...iState, bankName: "", branchName: "", ifscCode: "", accountNumber: "", bankHolderName: "", id: "", editModalModal: false })
                    updateState(initialState)
                }
            }
        } catch (error) {

        }

    }

    const handleDelete = async () => {
        try {
            console.log('handleValidation', handleValidation(), iState)
            if (id) {
                const data = {
                    id,
                }
                const deleteBankDetailResponse = await dispatch(deleteBankDetailApiSlice(data))
                console.log('addBankDetailResponse', deleteBankDetailResponse)
                if (deleteBankDetailResponse.payload.status === 200) {
                    toast.success('Bank Detail Deleted Successfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    updateState(initialState)
                    handleRefresh()
                }
            }
        } catch (error) {

        }

    }

    const handleRefresh = () => {
        const data = {
            search: "",
            startDate: "",
            endDate: "",
            timeFrame: "",
            page: '1'
        }
        dispatch(bankListApiSlice(data)).then((res) => {
            if (res?.payload?.status === 200) {
                updateState(initialState)
            }
        })
    }

    const handleFilter = () => {
        const data = {
            search,
            startDate,
            endDate,
            timeFrame,
            page: '1'
        }
        dispatch(bankListApiSlice(data))
    }

    const handlePageChange = (pageNumber) => {
        let ser = pageNumber * 10;
        updateSerialNo(ser);
        updateActivePage(pageNumber);
        let data = {
            search: "",
            startDate: "",
            endDate: "",
            timeFrame: "",
            page: pageNumber,
        };
        dispatch(bankListApiSlice(data))
    };

    useEffect(() => {
        if (timeFrame) {
            handleFilter()
        }
    }, [timeFrame])

    useEffect(() => {
        const data = {
            search: "",
            startDate: "",
            endDate: "",
            timeFrame: "",
            page: '1'
        }
        dispatch(bankListApiSlice(data))
    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <a
                            className="TitleLink"
                            onClick={handleAddModal}
                        >
                            Add Bank Account{" "}
                        </a>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                        name="search"
                                        value={search}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="startDate"
                                        value={startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="endDate"
                                        value={endDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilter}>Apply</button>
                                    <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: '5px' }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control" name="timeFrame" value={timeFrame} onChange={handleChange}>
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Bank Name</th>
                                    <th>Branch Name</th>
                                    <th>Accound ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bankData?.data?.result?.map((item) => {
                                        return (<tr>
                                            <td>001</td>
                                            <td>{item?.bankName}</td>
                                            <td>{item?.branchName}</td>
                                            <td>{item?.accountNumber}</td>
                                            <td>
                                                <div className="Actions">
                                                    <a
                                                        className="Blue"
                                                        onClick={() => handleEditModal(item)}
                                                    >
                                                        <i className="fa fa-pencil" />
                                                    </a>
                                                    <a
                                                        className="Red"
                                                        onClick={() => handleDeleteModal(item)}
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>)
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        <ul>
                            {bankData?.data?.result?.length > 0 && (
                                <Pagination
                                    activePage={ActivePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={bankData?.data?.result?.totalDocuments}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <Modal
                show={addModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="Category">
                                <a className="CloseModal" onClick={handleAddClose}>
                                    ×
                                </a>
                                <h3>Add Bank Account</h3>
                                <div className="form-group">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Holder Name"
                                        value={accountHolderName}
                                        name="accountHolderName"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.accountHolderName}</span>
                                </div>
                                <div className="form-group">
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Bank Name"
                                        value={bankName}
                                        name="bankName"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.bankName}</span>
                                </div>
                                <div className="form-group">
                                    <label>Branch Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Branch Name"
                                        value={branchName}
                                        name="branchName"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.branchName}</span>
                                </div>
                                <div className="form-group">
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Number"
                                        value={accountNumber}
                                        name="accountNumber"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.accountNumber}</span>
                                </div>
                                <div className="form-group">
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter IFSC Name"
                                        value={ifscCode}
                                        name="ifscCode"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.ifscCode}</span>
                                </div>
                                <div className="Buttons">
                                    <button className="Button ml-0" onClick={handleAddSubmit}>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={editModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="Category">
                                <a className="CloseModal" onClick={handleAddClose}>
                                    ×
                                </a>
                                <h3>Add Bank Account</h3>
                                <div className="form-group">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Holder Name"
                                        value={accountHolderName}
                                        name="accountHolderName"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.accountHolderName}</span>
                                </div>
                                <div className="form-group">
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Bank Name"
                                        value={bankName}
                                        name="bankName"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.bankName}</span>
                                </div>
                                <div className="form-group">
                                    <label>Branch Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Branch Name"
                                        value={branchName}
                                        name="branchName"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.branchName}</span>
                                </div>
                                <div className="form-group">
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Number"
                                        value={accountNumber}
                                        name="accountNumber"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.accountNumber}</span>
                                </div>
                                <div className="form-group">
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter IFSC Name"
                                        value={ifscCode}
                                        name="ifscCode"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.ifscCode}</span>
                                </div>
                                <div className="Buttons">
                                    <button className="Button ml-0" onClick={handleEditSubmit}>Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={deleteModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="Decline">
                                <a
                                    className="CloseModal"
                                >
                                    ×
                                </a>
                                <h3>Delete</h3>
                                <p>Are you sure you want to delete this Account ?</p>
                                <h4>
                                    <a onClick={handleAddClose}>
                                        no
                                    </a>
                                    <a onClick={handleDelete}>
                                        Yes
                                    </a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BankDetails;