import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../../Redux/Slices/Profile/profile";
import { useDispatch } from "react-redux";
const initialState = {
    showLogoutModal: false,
    userImage: "",
    userName: ""
}
const Header = () => {
    const [iState, updateState] = useState(initialState)
    const { showLogoutModal, userImage, userName } = iState
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    console.log('pathname', pathname)
    const handleLogoutModal = () => {
        updateState({ ...iState, showLogoutModal: true })
    }
    const handleLogout = () => {
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }
    useEffect(() => {
        dispatch(getProfile()).then((res) => {
            console.log('res', res)
            updateState({
                ...iState,
                userName: res?.payload?.data?.fullName,
                userImage: res?.payload?.data?.image
            })
        })
    }, [])
    const panelName = () => {
        if (pathname === '/support') {
            return 'Support'
        }
        if (pathname === '/faq') {
            return 'Faq'
        }
        if (pathname === '/guideLines') {
            return 'Guidelines'
        }
        if (pathname === '/supportDetail') {
            return 'Enquiry Details'
        }
        if (pathname === '/supportNotes') {
            return 'Resolution Notes'
        }
        if (pathname === '/set-availability') {
            return 'Set Availabliblity'
        }
        if (pathname === '/bank-details') {
            return 'Bank Detail'
        }
        if (pathname === '/profile') {
            return 'Profile'
        }
    }
    return (
        <div className="Header">
            <div className="HeaderLeft">
                <h4 style={{ color: `rgba(255, 144, 0, 1)` }}>{panelName()}</h4>
            </div>
            <div className="Navigation">
                {/* <div class="Bell">
          <a href="notification.html">
              <span class="Count">2</span>
              <i class="fa fa-bell"></i>
          </a>
      </div> */}
                <div className="Avater">
                    <a>
                        <figure>
                            <img src={userImage} />
                        </figure>
                        {userName}
                    </a>
                    <ul>
                        <li>
                            <figure>
                                <img src={userImage} />
                            </figure>
                            <h4>
                                {userName} <span>Administrator</span>
                            </h4>
                        </li>
                        <li>
                            <a
                                onClick={handleLogoutModal}
                                data-toggle="modal"
                                data-target="#LogOutModal"
                            >
                                <span>
                                    <i className="fa fa-sign-out" />
                                </span>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="clear" />
            </div>
            <Modal
                show={showLogoutModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <a className="CloseModal" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                            ×
                        </a>
                        <div className="LogOutModalArea">
                            <span>
                                <img src={require('../../../Assets/images/logout-icon.png')} />
                            </span>
                            <h5>Log Out</h5>
                            <p>Are you sure you want to log out?</p>
                            <div className="Buttons TwoButtons">
                                <button className="Button" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                                    No
                                </button>
                                <a
                                    type="button"
                                    onClick={handleLogout}
                                    className="Button Red"
                                >
                                    Yes
                                </a>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Header;