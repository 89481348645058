import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SideNav from '../sidenav/Sidenav'
import Header from '../header/Header'

const SupportDetail = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    console.log('state', state)
    return (
        <div>

            <Header />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="BuildingArea">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <p>
                                        <label>User Name</label>
                                        <span>{state?.firstName}</span>
                                    </p>
                                    <p>
                                        <label>Mobile Number</label>
                                        <span>{state?.contact}</span>
                                    </p>
                                    <p>
                                        <label>Email Id</label>
                                        <span>{state?.email}</span>
                                    </p>
                                    <p>
                                        <label>Message</label>
                                        <span>{state?.message}</span>
                                    </p>
                                    <p>
                                        <label>View Attached Files</label>
                                        <span>
                                            <a href={state?.fileAttach} target='_blank'>
                                                <i class="fa fa-file" aria-hidden="true" />
                                            </a>
                                        </span>
                                    </p>
                                    {/* <p>
                                        <label>Upload Document</label>
                                        <span>
                                            <input type="file" />
                                        </span>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SupportDetail