import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { sendReviewApi } from '../../../Redux/Slices/Appointments/PostReviewToUser'
import { toast } from 'react-toastify'

const initialState = {
    show: false,
    star: 2,
    comment: '',
    id: ""
}
const AppointmentCompleted = () => {
    const { appointmentDataList } = useSelector((state) => state?.appointmentData)
    const [iState, updateState] = useState(initialState)
    const { show, star, comment, id } = iState
    const dispatch = useDispatch()
    console.log('completed', appointmentDataList)
    const OpenUserReviewModal = (item) => {
        updateState({ ...iState, show: true, id: item?._id })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({ ...iState, [name]: value })
    }

    const handleSubmitReview = () => {
        const data = {
            bookingId: id,
            comments: comment,
            userRate: star
        }
        dispatch(sendReviewApi(data)).then((res) => {
            console.log('review response', res)
            if (res?.payload?.status === 200) {
                toast.success('Review Added Successfully', {
                    position: 'top-right',
                    autoClose: 1000
                })
                updateState(initialState)
            }
        })
    }
    return (
        <div>
            <div className="TitleBox justify-content-end m-0">
                <a
                    href="javascript:void(0);"
                    className="TitleLink"
                    style={{ marginTop: "-85px" }}
                >
                    <i className="fa fa-download" /> Download CSV
                </a>
            </div>
            <div className="Small-Wrapper">
                <div className="FilterArea">
                    <div className="FilterLeft">
                        <div className="form-group">
                            <label>Search</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search here.."
                            />
                        </div>
                        <div className="form-group">
                            <label>From Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>To Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>&nbsp;</label>
                            <button className="Button">Apply</button>
                            <button className="Button Cancel">
                                <i className="fa fa-refresh" />
                            </button>
                        </div>
                    </div>
                    <div className="FilterRight">
                        <div className="form-group">
                            <label>Timeframe</label>
                            <select className="form-control">
                                <option>Select </option>
                                <option value="Today">Today</option>
                                <option value="Week">This Week</option>
                                <option value="Month">This Month</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="TableList">
                <table>
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Appointment ID</th>
                            <th>Service Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Call Type &amp; Duration</th>
                            <th>View User Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            appointmentDataList?.data?.result?.map((item, index) => {
                                return (<tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.bookingNo}</td>
                                    <td>{item?.serviceData?.serviceName}</td>
                                    <td>{item?.slots?.date}</td>
                                    <td>{item?.slots?.startTime}</td>
                                    <td>Online (Video Call {item?.duration} min)</td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <Link className="Blue ml-0" to='/appointmentCompletedDetail' state={item}>
                                                <i className="fa fa-eye" />
                                            </Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="NewActions justify-content-center">
                                            <Link
                                                to='/genrateReport'
                                                className="Blue"
                                                state={item}
                                            >
                                                Generate &amp; Upload Report
                                            </Link>
                                            <a
                                                className="Yellow"
                                                onClick={() => OpenUserReviewModal(item)}
                                            >
                                                Rate User
                                            </a>
                                        </div>
                                    </td>
                                </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>
            <Modal
                show={show}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category">
                        <a className="CloseModal" onClick={() => updateState(initialState)}>
                            ×
                        </a>
                        <h3>Please provide your valuable feedback</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>1. Rate the user</label>
                                <div className="RatingStar">
                                    <span>
                                        <i className="fa-regular fa-star" />
                                    </span>
                                    <span>
                                        <i className="fa-regular fa-star" />
                                    </span>
                                    <span>
                                        <i className="fa-regular fa-star" />
                                    </span>
                                    <span>
                                        <i className="fa-regular fa-star" />
                                    </span>
                                    <span>
                                        <i className="fa-regular fa-star" />
                                    </span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Additional Comments</label>
                                <textarea
                                    rows={4}
                                    className="form-control"
                                    placeholder="Write your questions"
                                    defaultValue={comment}
                                    name='comment'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="Buttons">
                                <a onClick={() => handleSubmitReview()} className="Button ml-0" data-dismiss="modal">
                                    Submit
                                </a>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AppointmentCompleted