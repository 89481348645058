// import React, { useState } from "react";
// import StepBox from "./StepBox";
// import { uploadImage } from "../../Utils/FileUpload";


// const initialState = {
//     hearAstrotech: "",
//     ishearAstrotechIfOthers: "",
//     referAstrotech: "",
//     workingOnAnyOther: "",
//     aadharCard: "",
//     panCard: "",
//     passport: "",

// }
// const Document = () => {
//     const [iState, updateState] = useState(initialState)
//     const { hearAstrotech, ishearAstrotechIfOthers, referAstrotech, workingOnAnyOther, aadharCard, panCard, passport } = iState

//     const handleChange = (e) => {
//         const { name, value } = e.target
//         updateState({
//             ...iState,
//             [name]: value
//         })
//     }

//     const handleFileChange = async (e) => {
//         const { name } = e.target
//         const file = e.target.files[0]
//         const url = await uploadImage(file)
//         updateState({
//             ...iState,
//             [name]: url.location
//         })
//     }

//     const handleFinalSubmit = () => {
//         console.log(iState)
//     }
//     return (
//         <>
//             <StepBox />
//             <div className="SmallWrapperBox" style={{ width: "40%" }}>
//                 <div className="ConsultantService CommonForm">
//                     <form>
//                         <div className="row">
//                             <div className="col-sm-12">
//                                 <div className="form-group">
//                                     <label>Where Did You Hear AstroTech ?</label>
//                                     <select className="form-control" name="hearAstrotech" value={hearAstrotech} onChange={handleChange}>
//                                         <option value="">--select--</option>
//                                         <option value='instagram'>Instagram</option>
//                                         <option value='facebook'>Facebook</option>
//                                         <option value='others'>Others</option>
//                                     </select>
//                                 </div>
//                                 <div className="form-group">
//                                     <label>If others</label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="If Others"
//                                         name="ishearAstrotechIfOthers"
//                                         value={ishearAstrotechIfOthers}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div className="form-group">
//                                     <label>Did Anybody refer you to AstroTech ?</label>
//                                     <ul className="GenderUl">
//                                         <li>
//                                             <label className="Radio">
//                                                 {" "}
//                                                 Yes
//                                                 <input type="radio" name="referAstrotech" value="yes" checked={referAstrotech === 'yes'} onChange={handleChange} />
//                                                 <span className="checkmark" />
//                                             </label>
//                                         </li>
//                                         <li>
//                                             <label className="Radio">
//                                                 {" "}
//                                                 No
//                                                 <input type="radio" name="referAstrotech" value="no" checked={referAstrotech === 'no'} onChange={handleChange} />
//                                                 <span className="checkmark" />
//                                             </label>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="form-group">
//                                     <label>Are You Working on any other Platform ?</label>
//                                     <ul className="GenderUl">
//                                         <li>
//                                             <label className="Radio">
//                                                 {" "}
//                                                 Yes
//                                                 <input type="radio" name="workingOnAnyOther" value="yes" checked={workingOnAnyOther === 'yes'} onChange={handleChange} />
//                                                 <span className="checkmark" />
//                                             </label>
//                                         </li>
//                                         <li>
//                                             <label className="Radio">
//                                                 {" "}
//                                                 No
//                                                 <input type="radio" name="workingOnAnyOther" value="no" checked={workingOnAnyOther === 'no'} onChange={handleChange} />
//                                                 <span className="checkmark" />
//                                             </label>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 {workingOnAnyOther === 'yes' && (
//                                     <div className="form-group">
//                                         <label>If Yes,</label>
//                                         <input type="text" className="form-control" placeholder="If Yes" name="workingOnAnyOtherDetails" onChange={handleChange} />
//                                     </div>
//                                 )}
//                                 <h3 style={{ fontSize: 18, margin: "10px 0", fontWeight: 600 }}>
//                                     Upload Documents
//                                 </h3>
//                                 <div className="form-group">
//                                     <label>Aadhar Card</label>
//                                     <div className="UploadBox">
//                                         <div className="Upload">
//                                             <span>
//                                                 {" "}
//                                                 <i className="fa-solid fa-paperclip" /> Upload Aadhar Card
//                                             </span>
//                                             <input type="file" name="aadharCard" onChange={handleFileChange} />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="form-group">
//                                     <label>Pan Card</label>
//                                     <div className="UploadBox">
//                                         <div className="Upload">
//                                             <span>
//                                                 {" "}
//                                                 <i className="fa-solid fa-paperclip" /> Upload Pan Card
//                                             </span>
//                                             <input type="file" name="panCard" onChange={handleFileChange} />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="form-group">
//                                     <label>Passport</label>
//                                     <div className="UploadBox">
//                                         <div className="Upload">
//                                             <span>
//                                                 {" "}
//                                                 <i className="fa-solid fa-paperclip" /> Upload Passport
//                                             </span>
//                                             <input type="file" name="passport" onChange={handleFileChange} />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="Buttons TwoColorButtons">
//                             <a href="fill-others-details.html" className="ContinueBtn Red">
//                                 Back
//                             </a>
//                             <a

//                                 className="ContinueBtn"
//                                 onClick={handleFinalSubmit}
//                             >
//                                 Submit
//                             </a>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Document;





import React, { useEffect, useState } from "react";
import StepBox from "./StepBox";
import { uploadImage } from "../../Utils/FileUpload";
import { ProfileUpdate } from "../../Redux/Slices/ProfileUpdate/ProfileUpdate";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userIsRegistered } from "../../Redux/Slices/Login/Login";
import { clear } from "@testing-library/user-event/dist/clear";
import { Modal } from "react-bootstrap";

const initialState = {
    hearAstrotech: "",
    ishearAstrotechIfOthers: "",
    referAstrotech: "",
    workingOnAnyOther: "",
    workingOnAnyOtherDetails: "", // Added to store additional platform details
    aadharCard: "",
    panCard: "",
    passport: "",
    successModal: false,
    errors: {}
};

const Document = () => {
    const [iState, updateState] = useState(initialState);
    const { hearAstrotech, ishearAstrotechIfOthers, referAstrotech, workingOnAnyOther, workingOnAnyOtherDetails, aadharCard, panCard, passport, successModal, errors } = iState;
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('name', name, 'value', value)
        updateState({
            ...iState,
            [name]: value,
        });
    };

    const handleFileChange = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        const url = await uploadImage(file);
        updateState({
            ...iState,
            [name]: url.location,
        });
    };


    const handleValidation = () => {
        let error = {};
        let isValid = true;

        if (!hearAstrotech) {
            error.hearAstrotech = "Please select where you heard about AstroTech";
            isValid = false;
        }

        if (hearAstrotech === "others" && !ishearAstrotechIfOthers) {
            error.ishearAstrotechIfOthers =
                "Please specify where you heard about AstroTech";
            isValid = false;
        }

        if (!referAstrotech) {
            error.referAstrotech =
                "Please select if someone referred you to AstroTech";
            isValid = false;
        }

        if (!workingOnAnyOther) {
            error.workingOnAnyOther =
                "Please select if you are working on any other platform";
            isValid = false;
        }

        if (workingOnAnyOther === "yes" && !workingOnAnyOtherDetails) {
            error.workingOnAnyOtherDetails =
                "Please provide details of the other platform";
            isValid = false;
        }

        // You can add validation for document uploads if needed
        // For example, to check if at least one document is uploaded:

        // if (aadharCard && panCard && passport) {
        //     error.docError = "Please upload Identity document";
        //     isValid = false;
        // }
        if (!aadharCard) { //for educational document
            error.aadharCard = "Please upload Educational document";
            isValid = false;
        }

        if (!panCard) {
            error.panCard = "Please upload Identity proof document";
            isValid = false;
        }

        // if (!passport) {
        //     error.passport = "Please upload passport";
        //     isValid = false;
        // }

        updateState({
            ...iState,
            errors: error,
        });

        return isValid;
    }

    const handleFinalSubmit = () => {
        console.log(iState);
        //console.log('data', data)
        const data = {
            hearAstrotech,
            ishearAstrotechIfOthers,
            referAstrotech,
            workingOnAnyOther,
            platformName: workingOnAnyOtherDetails,
            aadharCard,
            panCard,
            passport,
            pageStatus: 'completed'
        }
        if (handleValidation()) {
            dispatch(ProfileUpdate(data)).then((res) => {
                console.log('before', res);
                if (res?.payload?.status === 200) {
                    updateState({ ...iState, successModal: true })
                    // navigate('/')
                }
            })
        }

    };
    const handleSuccess = () => {
        //updateState({ ...iState, successModal: false })
        updateState(initialState)
        navigate('/')
        handleLogout()
    }
    useEffect(() => {
        dispatch(userIsRegistered()).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('res', res?.payload?.data?.astrologer)
                updateState({
                    ...iState,
                    hearAstrotech: res?.payload?.data?.astrologer?.hearAstrotech ?? "",
                    ishearAstrotechIfOthers: res?.payload?.data?.astrologer?.ishearAstrotechIfOthers ?? "",
                    referAstrotech: res?.payload?.data?.astrologer?.referAstrotech ?? "",
                    workingOnAnyOther: res?.payload?.data?.astrologer?.workingOnAnyOther ?? "",
                    workingOnAnyOtherDetails: res?.payload?.data?.astrologer?.platformName ?? "",//changes
                    aadharCard: res?.payload?.data?.astrologer?.aadharCard ?? "",
                    panCard: res?.payload?.data?.astrologer?.panCard ?? "",
                    passport: res?.payload?.data?.astrologer?.passport ?? "",
                })
            }
        })
    }, [])

    const handleLogout = () => {
        console.log("handleLogout")
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }

    console.log('iState', iState)
    return (
        <>
            <div style={{ backgroundColor: "#f6f7ff" }}>
                <div className="Header Step">
                    <div className="HeaderLeft">
                        <div className="Logo">
                            <img src={require('../../Assets/images/Logo.png')} />
                        </div>
                    </div>
                    <div className="Navigation">
                        <div className="Avater">
                            <a >
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                {/* Bob Hyden */}
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        <span>Astrologer</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                        onClick={handleLogout}
                                    >
                                        <span >
                                            <i className="fa fa-sign-out" />
                                        </span>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <StepBox />
                <div className="SmallWrapperBox" style={{ width: "40%" }}>
                    <div className="ConsultantService CommonForm">
                        <form>
                            {/* ... (Your existing form fields) */}

                            <div className="form-group">
                                <label>Where Did You Hear AstroTech?</label>
                                <select
                                    className="form-control"
                                    name="hearAstrotech"
                                    value={hearAstrotech}
                                    onChange={handleChange}
                                >
                                    <option value="">--select--</option>
                                    <option value="instagram">Instagram</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="others">Others</option>
                                </select>
                                {errors.hearAstrotech && (
                                    <span className="text-danger">{errors.hearAstrotech}</span>
                                )}
                            </div>

                            {hearAstrotech === "others" && (
                                <div className="form-group">
                                    <label>If Others</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="If Others"
                                        name="ishearAstrotechIfOthers"
                                        value={ishearAstrotechIfOthers}
                                        onChange={handleChange}
                                    />
                                    {errors.ishearAstrotechIfOthers && (
                                        <span className="text-danger">{errors.ishearAstrotechIfOthers}</span>
                                    )}
                                </div>
                            )}

                            {/* ... (Your existing form fields) */}

                            <div className="form-group">
                                <label>Did Anybody Refer You to AstroTech?</label>
                                <ul className="GenderUl">
                                    <li>
                                        <label className="Radio">
                                            <input
                                                type="radio"
                                                name="referAstrotech"
                                                value="yes"
                                                checked={referAstrotech === "yes"}
                                                onChange={handleChange}
                                            />{" "}
                                            Yes
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio">
                                            <input
                                                type="radio"
                                                name="referAstrotech"
                                                value="no"
                                                checked={referAstrotech === "no"}
                                                onChange={handleChange}
                                            />{" "}
                                            No
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                                {errors.referAstrotech && (
                                    <span className="text-danger">{errors.referAstrotech}</span>
                                )}
                            </div>

                            {/* ... (Your existing form fields) */}


                            <div className="form-group">
                                <label>Are You Working on Any Other Platform?</label>
                                <ul className="GenderUl">
                                    <li>
                                        <label className="Radio">
                                            <input
                                                type="radio"
                                                name="workingOnAnyOther"
                                                value="yes"
                                                checked={workingOnAnyOther === "yes"}
                                                onChange={handleChange}
                                            />{" "}
                                            Yes
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio">
                                            <input
                                                type="radio"
                                                name="workingOnAnyOther"
                                                value="no"
                                                checked={workingOnAnyOther === "no"}
                                                onChange={handleChange}
                                            />{" "}
                                            No
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                                {errors.workingOnAnyOther && (
                                    <span className="text-danger">{errors.workingOnAnyOther}</span>
                                )}
                            </div>

                            {workingOnAnyOther === "yes" && (
                                <div className="form-group">
                                    <label>If Yes, Please Provide Details:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter platform details"
                                        name="workingOnAnyOtherDetails"
                                        value={workingOnAnyOtherDetails}
                                        onChange={handleChange}
                                    />
                                    {errors.workingOnAnyOtherDetails && (
                                        <span className="text-danger">{errors.workingOnAnyOtherDetails}</span>
                                    )}
                                </div>
                            )}

                            <h3 style={{ fontSize: 18, margin: "10px 0", fontWeight: 600 }}>
                                Upload Documents
                            </h3>

                            {/* ... (Your document upload fields) */}
                            <div className="form-group">
                                <label>Upload Educational Document</label>
                                <div className="UploadShowArea">
                                    <div className="UploadBox">
                                        <div className="Upload">
                                            <span>
                                                {" "}
                                                <i className="fa-solid fa-paperclip" /> Upload Document
                                            </span>
                                            <input type="file" name="aadharCard" onChange={handleFileChange} />

                                        </div>
                                        {errors.aadharCard && <span className="text-danger">{errors.aadharCard}</span>}
                                    </div>
                                    <div className="ShowCommonPicture">
                                        {
                                            aadharCard ?
                                                <img src={aadharCard} /> : ""
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Upload Identity Proof Document (Any of Aadhar Card, Pan Card and Passport)</label>
                                <div className="UploadShowArea">
                                    <div className="UploadBox">
                                        <div className="Upload">
                                            <span>
                                                {" "}
                                                <i className="fa-solid fa-paperclip" /> Upload Identity Proof Document
                                            </span>
                                            <input type="file" name="panCard" onChange={handleFileChange} />

                                        </div>
                                        {errors.panCard && <span className="text-danger">{errors.panCard}</span>}
                                    </div>
                                    <div className="ShowCommonPicture">
                                        {
                                            panCard ? <img src={panCard} width={212} height={173} /> : ""
                                        }
                                        {/* <img src={require("../../../src/Assets/images/Avatar-1.png")} /> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label>Passport</label>
                                <div className="UploadShowArea">
                                    <div className="UploadBox">
                                        <div className="Upload">
                                            <span>
                                                {" "}
                                                <i className="fa-solid fa-paperclip" /> Upload Passport
                                            </span>
                                            <input type="file" name="passport" onChange={handleFileChange} width={212} height={173} />
                                            {errors.passport && <span className="text-danger">{errors.passport}</span>}
                                        </div>
                                    </div>
                                    <div className="ShowCommonPicture">
                                        <img src={require("../../../src/Assets/images/Avatar-1.png")} />
                                        {
                                            passport ? <img src={passport} width={212} height={173} /> : ""
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <span style={{ color: 'red' }}>{errors?.docError}</span>
                            {/* Display document upload error (if any) */}


                            <div className="Buttons TwoColorButtons">
                                <Link type="button" className="ContinueBtn Red" to="/fill-others">
                                    Back
                                </Link>
                                <button type="button" className="ContinueBtn" onClick={handleFinalSubmit}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div >
                    <Modal
                        show={successModal}
                        className="ModalBox">
                        <Modal.Body>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="Category">
                                        {/* <a href="javascript:void(0);" class="CloseModal New Position1" data-dismiss="modal">×</a> */}
                                        <div className="CongratsArea">
                                            <span className="CheckIcon">
                                                <i className="fa-solid fa-check" />
                                            </span>
                                            <h2>Congrats!</h2>
                                            <p>Your registration has been completed.</p>
                                            <div className="Buttons">
                                                <a className="Button ml-0" onClick={handleSuccess}>
                                                    Continue
                                                </a>
                                                {/* <button type="button" class="Button" data-dismiss="modal">Check Booking</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </Modal.Body>
                    </Modal>
                </div >
            </div>
        </>
    );
};

export default Document;
