import { combineReducers } from '@reduxjs/toolkit';
import supportReducer from "./Slices/Support/Support"
import GuideLinesReducer from './Slices/GuideLines/GuideLines'
import locationReducer from './Slices/Location/location';
import FaqReducer from './Slices/Faq/Faq';
import BankReducer from './Slices/BankDetail/bank'
import SlotReducer from './Slices/SetAvailabliblity/setAvailliblity'
import ProfileReducer from './Slices/Profile/profile';
import AppointmentReducer from './Slices/Appointments/Appointments'
import walletReducer from './Slices/Wallet/WithdrawAmount'
import DashboardReducer from './Slices/Dashboard/DashBoard'

const rootReducer = combineReducers({
    support: supportReducer,
    guideLines: GuideLinesReducer,
    locationData: locationReducer,
    faqData: FaqReducer,
    bankData: BankReducer,
    slotData: SlotReducer,
    profileData: ProfileReducer,
    appointmentData: AppointmentReducer,
    walletData: walletReducer,
    dashboardData: DashboardReducer
});

export default rootReducer