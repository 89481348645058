import React, { useEffect, useState } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import AppointmentUpcoming from "./AppointmentUpcoming";
import AppointmentCompleted from "./AppointmentCompleted";
import AppointmentCancelled from "./AppointmentCancelled";
import AppointmentNoShow from "./AppointmentNoShow";
import { useDispatch } from "react-redux";
import { AppointmentListApi } from "../../../Redux/Slices/Appointments/Appointments";
import { Nav, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";


const initialState = {
    appointmentType: "upcoming"
}
const Appointment = () => {
    const [iState, updateState] = useState(initialState)
    const dispatch = useDispatch()
    const { type } = useParams()
    console.log('type', type)

    useEffect(() => {
        dispatch(AppointmentListApi(type))
    }, [type])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={type}
                    >
                        <div className="CommonTabs">
                            <div className='container'>
                                <Nav variant="tabs" className="nav nav-tabs">
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"
                                            eventKey='upcoming'
                                            as={Link}
                                            to="/appointment/upcoming"
                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Upcoming{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"
                                            eventKey='completed'
                                            as={Link}
                                            to="/appointment/completed"
                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Completed{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"
                                            eventKey='canceled'
                                            as={Link}
                                            to="/appointment/canceled"
                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Cancelled{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"
                                            eventKey='noshow'
                                            as={Link}
                                            to="/appointment/noshow"
                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            No Show{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content>
                            <Tab.Pane className="tab-pane" eventKey='upcoming'>
                                <AppointmentUpcoming />
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='completed'>
                                <AppointmentCompleted />
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='canceled'>
                                <AppointmentCancelled />
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='noshow'>
                                <AppointmentNoShow />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default Appointment;