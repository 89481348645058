import React from 'react'
import { useSelector } from 'react-redux'

const SkillsAndExp = () => {
    const { data } = useSelector((state) => state?.profileData?.profileData)
    console.log('data', data)
    return (
        <div><div className="tab-pane" id="SkillsExperienceDetails">
            <div className="Small-Wrapper">
                <fieldset disabled="">
                    <div className="CommonForm">
                        <form>
                            <div className="UserPlanBox">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Display Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Display Name"
                                                value={data?.fullName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Language</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="English, Hindi"
                                                value={data?.language?.map((item) => item?.name)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Experience (in years)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={data?.experience}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Skills</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Kundli Expert, Panchang, Baby Nameing"
                                                value={data?.skills?.map((item) => item?.name)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>How many hours you can contribute daily?</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Graduate"
                                                value={data?.hoursContribute}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>About Yourself </label>
                                            <textarea
                                                rows={5}
                                                className="form-control"
                                                value={data?.aboutYou}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </fieldset>
            </div>
        </div>
        </div>
    )
}

export default SkillsAndExp