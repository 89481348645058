import React from 'react'
import { useSelector } from 'react-redux'

const AdditionalInfo = () => {
    const { data } = useSelector((state) => state?.profileData?.profileData)
    return (
        <div><div className="tab-pane" id="AdditionalInformation">
            <div className="Small-Wrapper">
                <fieldset disabled="">
                    <div className="CommonForm">
                        <form>
                            <div className="UserPlanBox">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Main Source of Income</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Own Business"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>If main source of income is others</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Freelancer"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Are you currently working a full time job?</label>
                                            <ul className="UserPlan text-left">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input type="radio" name="plan" checked={data?.workingOnAnyOther === 'yes' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input type="radio" name="plan" checked={data?.workingOnAnyOther === 'no' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select your highest qualification</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Graduate"
                                                value={data?.highestQualification}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>If highest qualification is others</label>
                                            <input type="text" className="form-control" value={data?.isHighestQualificationIsOthers} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>From where did you learn Astrology?</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Facebook"
                                                value={data?.learnAstrology}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Instagram profile link (optional)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="avinash.instagram.com"
                                                value={data?.instaLink}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Facebook profile link (optional)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="avinash.facebook.com"
                                                value={data?.facebookLink}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Min. Earning Expectations from AstroTech (in month)
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="1 lakh"
                                                value={data?.minEarnExpected}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Where did you hear Astrotech?</label>
                                            <select className="form-control">
                                                <option>{data?.hearAstrotech}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>If Others</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Other"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Did anybody refer you to Astrotech?</label>
                                            <ul className="UserPlan text-left">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input type="radio" name="plan1" checked={data?.referAstrotech === 'yes' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input type="radio" name="plan1" defaultChecked="" checked={data?.referAstrotech !== 'yes' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Are you working on any other online platform?</label>
                                            <ul className="UserPlan text-left">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input type="radio" name="plan" checked={data?.workingOnAnyOther === 'yes' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input type="radio" name="plan" defaultChecked="" checked={data?.workingOnAnyOther !== 'yes' ? true : false} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Aadhar Card</label>
                                            <figure>
                                                <a href={data?.aadharCard}>
                                                    <i className="fa-solid fa-file-pdf" />
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>PAN Card</label>
                                            <figure>
                                                <a href={data?.panCard}>
                                                    <i className="fa-solid fa-file-pdf" />
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Passport</label>
                                            <span>
                                                <figure>
                                                    <a href={data?.passport}>
                                                        <i className="fa-solid fa-file-pdf" />
                                                    </a>
                                                </figure>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label>Marital Status</label>
                                                    <select class="form-control">
                                                        <option>--select--</option>
                                                        <option>married</option>
                                                        <option>Unmarried</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                </div>
                            </div>
                        </form>
                    </div>
                </fieldset>
            </div>
        </div>
        </div>
    )
}

export default AdditionalInfo