import axios from 'axios';
import { credAndUrl } from '../Config/config'
import { toast } from 'react-toastify';
// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: credAndUrl.BASE_URL, // Your API base URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("astrologerToken");
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Modify response data or handle response here
        console.log('Response form interceptor', response)
        // if (response?.data?.status === 500 || response?.data?.status === 401) {
        //     if (response?.data?.status === 401) {
        //         toast.error('Your account have been disabled by administrator due to any suspicious activity', {
        //             position: 'top-right',
        //             autoClose: 1000
        //         })
        //     }
        //     window.localStorage.removeItem('astrologerToken')
        //     window.location.href = '/' // Redirect to login page on unauthorized access
        // }
        return response;
    },
    (error) => {
        // Handle response error
        console.log('Error in interceptor', error)

        if (error?.response.status === 401) {
            // window.localStorage.removeItem('subaToken')
            // window.location.href = 'http://3.13.196.242/subaAdmin/#/'
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
