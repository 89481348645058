import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const addBankDetailApiSlice = createAsyncThunk(
    "addBankDetailApi/postaddBankDetailApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `bank/add-bank`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const bankListApiSlice = createAsyncThunk(
    "bankListData/getbankListData",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `bank/bank-list?search=${payload?.search}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&timeFrame=${payload?.timeFrame}&page=${payload?.page}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    bankData: [],
    status: false
}
const bankDataSlice = createSlice({
    name: 'bankDataSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bankListApiSlice.pending, (state) => {
                state.status = true;
            })
            .addCase(bankListApiSlice.fulfilled, (state, action) => {
                state.status = false;
                state.bankData = action.payload;
            })
            .addCase(bankListApiSlice.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default bankDataSlice.reducer;


//================ edit ========================//

export const editBankDetailApiSlice = createAsyncThunk(
    "editBankDetailApi/posteditBankDetailApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.put(
                `bank/edit-bank`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


//=================delete =============================//

export const deleteBankDetailApiSlice = createAsyncThunk(
    "deleteBankDetailApi/postdeleteBankDetailApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `bank/delete-bank`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);