import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";



export const getUserDetailApi = createAsyncThunk(
    "getUserDetailApi/getgetUserDetailApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `appointments/details?bookingId=${payload}`, payload,
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//generateReportApi

export const generateReportApi = createAsyncThunk(
    "generateReportApi/postgenerateReportApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `appointments/add-report`, payload,
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

