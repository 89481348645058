export const credAndUrl = {
    BASE_URL: '/api/astrologer/',
    bucketConfig: {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName: process.env.REACT_APP_BUCKET_DIR,
        region: process.env.REACT_APP_BUCKET_REGION,
        accessKeyId: process.env.REACT_APP_BUCKET_ACCESSKEYID,
        secretAccessKey: process.env.REACT_APP_BUCKET_SECRETACCESSKEY,
    },
    astrologyApiUser: process.env.REACT_APP_ASTROLOGY_USER,
    astrologyApiPassword: process.env.REACT_APP_ASTROLOGY_PASSWORD,
}