import React, { useEffect } from "react";
import Sidenav from '../sidenav/Sidenav'
import Header from "../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { DashboardData } from "../../../Redux/Slices/Dashboard/DashBoard";
import { AppointmentListApi } from "../../../Redux/Slices/Appointments/Appointments";
import { Link } from "react-router-dom";
const Dashboard = () => {
    const dispatch = useDispatch()
    const data = useSelector((state) => state?.dashboardData?.DashboardData?.data)
    const { appointmentDataList } = useSelector((state) => state?.appointmentData)
    console.log('upcominglist', appointmentDataList)
    console.log('dashboardData', data)

    useEffect(() => {
        dispatch(DashboardData())
        dispatch(AppointmentListApi('upcoming'))
    }, [])
    return (
        <>
            <Sidenav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="Small-Wrapper">
                                <div className="TitleBox">
                                    <h4 className="Title">Welcome to your dashboard</h4>
                                </div>
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button">Apply</button>
                                            <button className="Button Cancel">
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control">
                                                <option>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="TitleBox">
                                        <h4 className="Title">Appointment Details</h4>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/appointment-icon.png')} />
                                        </span>
                                        <h2>Total Appointment</h2>
                                        <article>
                                            <h3>{data?.totalBookings}</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            {/* <img src={require('../../../../Assets/images/appointment-icon-2.png')} /> */}
                                        </span>
                                        <h2>Total Completed Appointments</h2>
                                        <article>
                                            <h3>{data?.totalCompleted}</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/appointment-icon.png')} />
                                        </span>
                                        <h2>Total Cancelled Appointments</h2>
                                        <article>
                                            <h3>{data?.totalCancel}</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            {/* <img src={require('../../../Assets/images/appointment-icon-2.png')} /> */}
                                        </span>
                                        <h2>Total No Show Appointments</h2>
                                        <article>
                                            <h3>{data?.totalNoshow}</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="TitleBox">
                                        <h4 className="Title">Revenue Details</h4>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/per-call.png')} />
                                        </span>
                                        <h2>Total Earning Per Call</h2>
                                        <article>
                                            <h3>--</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/wallet-balance.png')} />
                                        </span>
                                        <h2>Wallet Balance</h2>
                                        <article>
                                            <h3>{data?.totalWallet}</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="UserBoxDashboard">
                                <figure>
                                    <span>
                                        <img src={require('../../../Assets/images/Avatar-1.png')} />
                                    </span>
                                </figure>
                                <figcaption>
                                    <h6 className="Info">Info</h6>
                                    <p>
                                        <strong>Astrologer Name </strong>
                                        <span>{data?.astrologer?.fullName} </span>
                                    </p>
                                    <p>
                                        <strong>Total Experience</strong>
                                        <span>{data?.astrologer?.experience} Years</span>
                                    </p>
                                    <p>
                                        <strong>Contact Number</strong>
                                        <span>+{data?.astrologer?.contact}</span>
                                    </p>
                                    <p>
                                        <strong>Ratings</strong>
                                        <span>{data?.astrologer?.rating}</span>
                                    </p>
                                    {/* <p>
                                        <strong>Your Availability Status</strong>
                                        <span className="Actions">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider" />
                                            </label>
                                        </span>
                                    </p> */}
                                    <p className="Notes">
                                        <span>Note:</span> Your payout ranges from Rs. 400-600 for 15
                                        minutes and Rs. 1000-1200 for 30 minutes, depending on your
                                        ratings. Higher ratings mean higher pay, while lower ratings
                                        result in lower pay.
                                    </p>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                    <div className="TitleBox">
                        <h4 className="Title">Upcoming Appointments</h4>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Astrologer Name</th>
                                    <th>Date &amp; Time</th>
                                    <th>Call Type &amp; Duration</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    appointmentDataList?.data?.result?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>01</td>
                                                <td>{item?.astrologerData?.fullName}</td>
                                                <td>{item?.slots?.date}, {item?.slots?.startTime}</td>
                                                <td>Video Call (online)</td>
                                                <td>
                                                    <div className="Actions">
                                                        <Link className="Blue" to='/userDetail' state={item}>
                                                            <i className="fa fa-eye" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;