import React, { useEffect } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { Nav, Tab } from "react-bootstrap";
import PersonalDetail from "./PersonalDetail";
import SkillsAndExp from "./SkillsAndExp";
import AdditionalInfo from "./AdditionalInfo";
import Services from "./Services";
import { getProfile } from "../../../Redux/Slices/Profile/profile";
import { useDispatch } from "react-redux";

const Profile = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProfile())
    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div class="TitleBox"><h4 class="Title"></h4></div>
                    <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey='personal detail'
                    >
                        <div className="CommonTabs">
                            <div className='container'>
                                <Nav variant="tabs" className="nav nav-tabs">
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"

                                            eventKey='personal detail'

                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Personal Detail{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"

                                            eventKey='skillAndExperience'

                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Skills And Experience Detail{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"
                                            eventKey='additionalInfo'
                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Additional Information{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link"
                                            eventKey='services'
                                        >
                                            <span>
                                                {/* <img src={require('../../Assets/images/free-kundli.png')} /> */}
                                            </span>
                                            Services{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content>
                            <Tab.Pane className="tab-pane" eventKey='personal detail'>
                                <PersonalDetail />
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='skillAndExperience'>
                                <SkillsAndExp />
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='additionalInfo'>
                                <AdditionalInfo />
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='services'>
                                <Services />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </div>
            </div>

        </>
    )
}
export default Profile;