import React from 'react'
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getKundliData } from '../../../Redux/Slices/AstroTechKundliApis/Getdetail';


const AppointmentCompletedDetail = () => {
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { } = state
    const navigate = useNavigate()
    console.log('state in appointmentCompletedDetail', state)
    const getUserDetailKundli = (profile) => {
        const data = {
            "name": "Garima Tyagi",
            "gender": "female",
            "day": 8,
            "month": 11,
            "year": 1997,
            "hour": 23,
            "min": 25,
            "lat": 28.83,
            "lon": 77.58,
            "language": "en",
            "tzone": 5.5,
            "place": "modinagar, uttar pardesh, India",
            "chart_style": "NORTH_INDIAN",
            "footer_link": "https://www.example.com",
            "logo_url": "https://www.example.com/logo.png",
            "company_name": "AstroTech Solutions Pvt. Ltd.",
            "company_info": "We provide astrology solutions and personalized predictions.",
            "domain_url": "https://www.example.com",
            "company_email": "support@example.com",
            "company_landline": "+91-22123222",
            "company_mobile": "+91-1212121212"
        }

        dispatch(getKundliData(data)).then((res) => {
            console.log('Kundli response', res);
            if (res?.payload?.status) {
                const pdfUrl = res?.payload?.pdf_url;

                // Create a hidden anchor tag
                const a = document.createElement('a');
                a.href = pdfUrl; // Set the PDF file URL
                a.download = 'kundli_report.pdf'; // Set a default filename for download
                document.body.appendChild(a); // Append the anchor to the body
                a.click(); // Programmatically click the anchor to trigger download
                document.body.removeChild(a); // Remove anchor from the DOM after download
            }
        });

    }
    return (
        <div>
            <Header />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Members Details</h4>
                        <div className="BackArrow m-0">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="BuildingArea">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <a

                                        className="View"
                                        onClick={() => getUserDetailKundli()}
                                    >
                                        View Kundli
                                    </a>
                                    <h4>Member 1 Details</h4>
                                    <p>
                                        <label>Full Name</label>
                                        <span>Radha Kumari</span>
                                    </p>
                                    <p>
                                        <label>Date of Birth</label>
                                        <span>02/01/1997</span>
                                    </p>
                                    <p>
                                        <label>Time of Birth</label>
                                        <span>07:16:35 AM</span>
                                    </p>
                                    <p>
                                        <label>Location</label>
                                        <span>
                                            Block 147, Noida sector 63 A{" "}
                                            <a href="javascript:void(0);">
                                                <i className="fa-solid fa-location-dot" />
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <a
                                        href="javascript:void(0);"
                                        className="View"
                                        data-toggle="modal"
                                        data-target="#ViewKundli"
                                    >
                                        View Kundli
                                    </a>
                                    <h4>Member 2 Details</h4>
                                    <p>
                                        <label>Full Name</label>
                                        <span>Radha Kumari</span>
                                    </p>
                                    <p>
                                        <label>Date of Birth</label>
                                        <span>02/01/1997</span>
                                    </p>
                                    <p>
                                        <label>Time of Birth</label>
                                        <span>07:16:35 AM</span>
                                    </p>
                                    <p>
                                        <label>Location</label>
                                        <span>
                                            Block 147, Noida sector 63 A{" "}
                                            <a href="javascript:void(0);">
                                                <i className="fa-solid fa-location-dot" />
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="BuildingPackage">
                                    <h4>View Questions</h4>
                                    <p>
                                        <label>Question 1</label>
                                        <span>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                            Perspiciatis nemo accusantium modi eos aspernatur enim quae sunt
                                            cum, commodi debitis ut animi officiis maiores quasi, assumenda
                                            totam laudantium repellendus reprehenderit!
                                        </span>
                                    </p>
                                    <p>
                                        <label>Question 2</label>
                                        <span>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                            Perspiciatis nemo accusantium modi eos aspernatur enim quae sunt
                                            cum, commodi debitis ut animi officiis maiores quasi, assumenda
                                            totam laudantium repellendus reprehenderit!
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AppointmentCompletedDetail