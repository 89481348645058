import React, { useEffect } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { faqApi } from "../../../Redux/Slices/Faq/Faq";
import { Accordion } from "react-bootstrap";

const Faq = () => {
    const dispatch = useDispatch()
    const { faqData } = useSelector((state) => state?.faqData)
    console.log('faq Data', faqData)

    useEffect(() => {
        dispatch(faqApi())
    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    {/* <div className="TitleBox">
                        <h4 className="Title">FAQs</h4>
                        <a
                            className="TitleLink"
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AddFaq"
                        >
                            Add Faq
                        </a>
                    </div> */}
                    <div className="StaticBoxArea">
                        <Accordion>
                            {
                                faqData?.data?.length > 0 ? faqData?.data?.map((item, index) => {
                                    return (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>
                                                {
                                                    <p dangerouslySetInnerHTML={{ __html: item?.faqName }} />

                                                }
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    <p dangerouslySetInnerHTML={{ __html: item?.description }} />

                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        // <div id="accordion">
                                        //     <div className="card">
                                        //         <div className="card-header"
                                        //             data-toggle="collapse"
                                        //             data-parent="#accordion"
                                        //             href="#collapse1"
                                        //             aria-expanded="true"
                                        //         >
                                        //             <h4>Lorem ipsum dolor sit amet.</h4>
                                        //             <a href="faq-edit.html">
                                        //                 <i className="fa fa-pencil" />
                                        //             </a>
                                        //         </div>
                                        //         <div id="collapse1" className="panel-collapse collapse in">
                                        //             <div className="card-body">
                                        //                 <p>
                                        //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                                        //                     euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                                        //                     accumsan et viverra justo commodo. Proin sodales pulvinar sic
                                        //                     tempor. Sociis natoque penatibus et magnis dis parturient
                                        //                     montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                        //                     pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin
                                        //                     sapien nunc accuan eget.
                                        //                 </p>
                                        //                 <p>
                                        //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                                        //                     euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                                        //                     accumsan et viverra justo commodo. Proin sodales pulvinar sic
                                        //                     tempor. Sociis natoque penatibus et magnis dis parturient
                                        //                     montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                        //                     pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin
                                        //                     sapien nunc accuan eget.
                                        //                 </p>
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                    )
                                }) : "No Data Found"
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Faq;