import React, { useEffect } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { guideLinesApi } from "../../../Redux/Slices/GuideLines/GuideLines";

const Guideline = () => {
    const dispatch = useDispatch()
    const guideLineData = useSelector((state) => state)
    console.log('guideLineData', guideLineData?.guideLines?.guideLinesData?.data)


    useEffect(() => {
        dispatch(guideLinesApi())
    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="Small-Wrapper">
                        <div className="TitleBox">
                            {/* <h4 className="Title">Guidelines</h4> */}
                        </div>
                        <div className="NotificationsBox">
                            <ul>
                                {
                                    guideLineData?.guideLines?.guideLinesData?.data?.map((item) => {
                                        return (<li>
                                            <aside>
                                                <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                                                {/* <h6>22 Feb 2023, 10:30 PM</h6> */}
                                            </aside>
                                        </li>)
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Guideline;