import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../../Redux/Slices/Profile/profile";

const initialState = {
    showLogoutModal: false,
    userName: "",
    userImage: ""
}
const SideNav = () => {
    const [iState, updateState] = useState(initialState)
    const { showLogoutModal, userName, userImage } = iState
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const handleLogoutModal = () => {
        updateState({ ...iState, showLogoutModal: true })
    }
    const handleLogout = () => {
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }


    return (
        <div className="SidenavBar">
            <div className="Logo">
                <img src={require('../../../Assets/images/Logo.png')} />
            </div>
            <ul>
                <li className={pathname === '/dashboard' ? "active" : ""}>
                    <Link to="/dashboard">
                        <span>
                            <i className="fa fa-tachometer" />
                        </span>
                        Dashboard
                    </Link>
                </li>
                <li className={pathname === '/profile' ? "active" : ""}>
                    <Link to='/profile'>
                        <span>
                            <i className="fa fa-user" />
                        </span>
                        Profile
                    </Link>
                </li>
                <li className={pathname === '/bank-details' ? "active" : ""}>
                    <Link to="/bank-details">
                        <span>
                            <i className="fa-solid fa-house" />
                        </span>
                        Bank Details
                    </Link>
                </li>
                <li className={pathname === '/appointment' ? "active" : ""}>
                    <Link to='/appointment/upcoming'>
                        <span>
                            <i className="fa fa-font" />
                        </span>
                        Appointment
                    </Link>
                </li>
                <li className={pathname === '/set-availability' ? "active" : ""}>
                    <Link to='/set-availability'>
                        <span>
                            <i className="fa-solid fa-clock" />
                        </span>
                        Set Availability
                    </Link>
                </li>
                <li className={pathname === '/wallet' ? "active" : ""}>
                    <Link to='/wallet'>
                        <span>
                            <i className="fa-solid fa-wallet" />
                        </span>
                        Wallet
                    </Link>
                </li>
                <li className={pathname === '/support' ? "active" : ""}>
                    <Link to="/support">
                        <span>
                            <i className="fa-solid fa-ticket" />
                        </span>
                        Support
                    </Link>
                </li>
                <li className={pathname === '/faq' ? "active" : ""}>
                    <Link to='/faq'>
                        <span>
                            <i className="fa-solid fa-ticket" />
                        </span>
                        FAQs
                    </Link>
                </li>
                <li className={pathname === '/guideLines' ? "active" : ""}>
                    <Link to='/guideLines'>
                        <span>
                            <i className="fa-brands fa-glide" />
                        </span>
                        Guidelines
                    </Link>
                </li>
                <li>
                    <a
                        data-toggle="modal"
                        data-target="#LogOutModal"
                        onClick={handleLogoutModal}
                    >
                        <span>
                            <i className="fa fa-sign-out" />
                        </span>
                        Logout
                    </a>
                </li>
            </ul>
            <Modal
                show={showLogoutModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <a className="CloseModal" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                            ×
                        </a>
                        <div className="LogOutModalArea">
                            <span>
                                <img src={require('../../../Assets/images/logout-icon.png')} />
                            </span>
                            <h5>Log Out</h5>
                            <p>Are you sure you want to log out?</p>
                            <div className="Buttons TwoButtons">
                                <button className="Button" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                                    No
                                </button>
                                <a
                                    type="button"
                                    onClick={handleLogout}
                                    className="Button Red"
                                >
                                    Yes
                                </a>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SideNav;