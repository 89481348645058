import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const getCallInfoApi = createAsyncThunk(
    "getCallInfoApi/getgetCallInfoApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `appointments/get-call-details?bookingId=${payload}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
