import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";



export const updateCallStatusApi = createAsyncThunk(
    "updateCallStatusApi/postupdateCallStatusApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `appointments/status-update`, payload,
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);