import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const createSlot = createAsyncThunk(
    "createSlotApi/postcreateSlotApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `slot-availability/add-slots`,
                payload
            );
            return response?.data;
        } catch (e) {
            console.log('Error', e)
        }
    })

export const getSlots = createAsyncThunk(
    "getSlotsApi/getSlotsApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `slot-availability/get-days`
            );
            return response?.data;
        } catch (e) {
            console.log('Error', e)
        }
    })

const initialState = {
    slotsData: [],
    status: false
}
const slotDataSlice = createSlice({
    name: 'slotsDataSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSlots.pending, (state) => {
                state.status = true;
            })
            .addCase(getSlots.fulfilled, (state, action) => {
                state.status = false;
                state.slotsData = action.payload;
            })
            .addCase(getSlots.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default slotDataSlice.reducer;
//================= edit slot ============================//
export const editSlot = createAsyncThunk(
    "editSlotApi/posteditSlotApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.put(
                `slot-availability/edit-slot`,
                payload
            );
            return response?.data;
        } catch (e) {
            console.log('Error', e)
        }
    })


//===================== Delete Slot ==================================//
export const deleteSlot = createAsyncThunk(
    "deleteSlotApi/postdeleteSlotApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `slot-availability/delete-slot`,
                payload
            );
            return response?.data;
        } catch (e) {
            console.log('Error', e)
        }
    })