import React, { useEffect, useState } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createSlot, deleteSlot, editSlot, getSlots } from "../../../Redux/Slices/SetAvailabliblity/setAvailliblity";
import moment from "moment";

const initialState = {
    globalAddModal: false,
    addModal: false,
    editModal: false,
    slot: "",
    startTime: "",
    endTime: "",
    status: "",
    id: "",
    errors: {}
}
const SetAvailability = () => {
    const [iState, updateState] = useState(initialState)
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const { addModal, slot, startTime, endTime, status, globalAddModal, id, editModal, errors } = iState
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data } = useSelector((state) => state?.slotData?.slotsData)
    console.log('slot list', data)
    let dayData = []
    for (let i = 0; i < weekDays?.length; i++) {
        for (let j = 0; j < weekDays?.length; j++) {
            if (weekDays[i] === data?.[j]?.dayDetails?.[0]?.day) {
                dayData.push(data?.[j])
            }
        }
    }
    console.log('day data', dayData)

    const handleValidation = () => {
        let error = {}
        let isValid = true;
        if (!startTime) {
            error.startTime = "Start Time is required";
            isValid = false;
        }
        if (!endTime) {
            error.endTime = "End Time is required";
            isValid = false;
        }
        if (!status) {
            error.status = "Slot Status is required";
            isValid = false;
        }
        updateState({
            ...iState,
            errors: error
        })
        return isValid;
    }

    const handleGlobalAddModal = () => {
        updateState({ ...iState, globalAddModal: true, addFrom: 'global' })
    }
    const handleAddModal = (item) => {
        updateState({ ...iState, addModal: true, addFrom: 'day', id: item?._id })
    }
    const handleEditModal = (item) => {
        console.log('edit modal item', item)
        updateState({
            ...iState,
            editModal: true,
            startTime: item?.startTime,
            endTime: item?.endTime,
            status: item?.slotStatus,
            id: item?._id
        })
    }
    const handleClose = () => {
        // updateState({ ...iState, addModal: false, globalAddModal: false })
        updateState(initialState)
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({ ...iState, [name]: value })
    }

    const showGlobalAddButtonFunc = () => {
        console.log('inside global add function')
        for (let i = 0; i < data?.length; i++) {
            if (data?.[i]?.slots?.[0]?.startTime) {
                return false
            }
        }
        return true
    }
    console.log('iState', iState)
    const handleAddSlot = (e) => {
        e.preventDefault()
        console.log('add slot', iState)
        if (handleValidation()) {
            const data = {
                slots: [{
                    day: id,
                    slotStatus: status,
                    startTime: startTime,
                    endTime: endTime
                }]
            }
            dispatch(createSlot(data)).then((res) => {
                console.log('res of add slot', res)
                if (res?.payload?.status === 200) {
                    updateState(initialState)
                    toast.success('Slot Added Sucessfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    dispatch(getSlots())
                } else {
                    toast.error(res?.payload?.message, {
                        position: 'top-right',
                        autoClose: 1000
                    })
                }
            })
        }

    }
    const handleEditSlot = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            const data = {
                slotId: id,
                startTime,
                endTime,
                slotStatus: status
            }
            dispatch(editSlot(data)).then((res) => {
                console.log('res of edit slot', res)
                if (res?.payload?.status === 200) {
                    updateState(initialState)
                    toast.success('Slot Edited Sucessfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    dispatch(getSlots())
                } else {
                    toast.error(res?.payload?.message, {
                        position: 'top-right',
                        autoClose: 1000
                    })
                }
            })
        }

    }
    const handleDeleteSlot = (item) => {
        const data = {
            slotId: item?._id
        }
        dispatch(deleteSlot(data)).then((res) => {
            console.log('res of delete slot', res)
            if (res?.payload?.status === 200) {
                toast.success('Slot Deleted Sucessfully', {
                    position: 'top-right',
                    autoClose: 1000
                })
                dispatch(getSlots())
            }
        })
    }
    useEffect(() => {
        dispatch(getSlots())

    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="Small-Wrapper">
                        {
                            showGlobalAddButtonFunc() ? <a style={{ float: 'right', backgroundColor: "#ff9000", padding: '5px 15px', color: '#fff', border: '2px solid #ff9000', borderRadius: '5px', fontWeight: '600', marginTop: '-17px' }} onClick={handleGlobalAddModal}>Add Slot</a> : null
                        }

                        <div className="SetAvailabilityArea">
                            {
                                dayData?.map((item1) => {
                                    return (<div className="SlotBoxMain">
                                        <div className="SlotBox" >
                                            <span>{item1?.dayDetails?.[0]?.day}</span>
                                            {
                                                item1?.slots?.map((item, index) => {
                                                    const startTime = moment(item?.startTime, "HH:mm");
                                                    const endTime = moment(item?.endTime, "HH:mm");
                                                    return (
                                                        <p style={{ border: '2px soild red' }}>
                                                            <strong>Slot {index + 1}</strong>
                                                            <strong>From: </strong>{startTime.format('hh:mm A')} - <strong>To: </strong>{endTime.format('hh:mm A')}{'   '}

                                                            <button onClick={() => handleEditModal(item)}>
                                                                <i className="fa-solid fa-pen-to-square" /> Edit
                                                            </button>
                                                            <button
                                                                style={{
                                                                    float: 'right',
                                                                    margin: '0 5px 0 0',
                                                                    background: item?.slotStatus === 'on' ? '#6EC207' : '#F95454',  // Fix: no extra curly braces here
                                                                    borderRadius: '5px',
                                                                    width: '80px',
                                                                    cursor: 'none'
                                                                }}
                                                            > {item?.slotStatus === 'on' ? 'Active' : 'In Active'}</button>

                                                            {
                                                                item1?.slots?.length > 1 ? <button onClick={() => handleDeleteSlot(item)} style={{ background: 'red' }}>
                                                                    {" "}
                                                                    <i className="fa-solid fa-trash" /> Delete
                                                                </button> : ""
                                                            }

                                                        </p>)
                                                })
                                            }
                                        </div>
                                        <button onClick={() => handleAddModal(item1)}>
                                            <i className="fa-solid fa-plus" />
                                        </button>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div >
            <Modal
                show={addModal || globalAddModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="Category">
                                <a className="CloseModal" onClick={handleClose}>
                                    ×
                                </a>
                                <h3>Add Slot</h3>
                                <div className="form-group">
                                    <label>Select Start Time</label>
                                    <div className="d-flex flex-column">
                                        <input type="time" className="form-control" name="startTime" value={startTime} onChange={handleChange} />
                                        {
                                            errors.startTime && <span className='text-danger'>{errors.startTime}</span>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Select End Time</label>
                                    <div className="d-flex flex-column">
                                        <input type="time" className="form-control" name="endTime" value={endTime} onChange={handleChange} />
                                        {
                                            errors.endTime && <span className='text-danger'>{errors.endTime}</span>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Slot Status</label>
                                    <select className="form-control" name="status" value={status} onChange={handleChange}>
                                        <option value=''>Select</option>
                                        <option value='on'>On</option>
                                        <option value='off'>Off</option>
                                    </select>
                                    {
                                        errors.status && <span className='text-danger'>{errors.status}</span>
                                    }
                                </div>
                                <div className="Buttons">
                                    <button className="Button ml-0" onClick={handleAddSlot}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={editModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="Category">
                                <a className="CloseModal" onClick={handleClose}>
                                    ×
                                </a>
                                <h3>Add Slot</h3>
                                <div className="form-group">
                                    <label>Select Start Time</label>
                                    <div className="d-flex flex-column">
                                        <input type="time" className="form-control" name="startTime" value={startTime} onChange={handleChange} />
                                        {
                                            errors.startTime && <span className='text-danger'>{errors.startTime}</span>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Select End Time</label>
                                    <div className="d-flex flex-column">
                                        <input type="time" className="form-control" name="endTime" value={endTime} onChange={handleChange} />
                                        {
                                            errors.endTime && <span className='text-danger'>{errors.endTime}</span>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Slot Status</label>
                                    <select className="form-control" name="status" value={status} onChange={handleChange}>
                                        <option value=''>Select</option>
                                        <option value='on'>On</option>
                                        <option value='off'>Off</option>
                                    </select>
                                    {
                                        errors.status && <span className='text-danger'>{errors.status}</span>
                                    }
                                </div>
                                <div className="Buttons">
                                    <button className="Button ml-0"
                                        onClick={handleEditSlot}
                                    >
                                        Update Slot
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default SetAvailability