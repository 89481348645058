import React, { useEffect, useState } from "react";
import { RemoteUser, useJoin, useRemoteUsers, useRTCClient } from "agora-rtc-react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCallInfoApi } from "../../../Redux/Slices/Appointments/GetCallInfo";
import { LocalUser, useLocalCameraTrack, useLocalMicrophoneTrack, usePublish } from "agora-rtc-react";
import { Modal } from "react-bootstrap";
import { updateCallStatusApi } from "../../../Redux/Slices/Appointments/UpdateCallStatus";

const initialState = {
    channelName: "",
    token: "",
    calling: false,
    micOn: true,
    cameraOn: true,
    channelLeaveConfModal: false,
};

const APP_ID = process.env.REACT_APP_AGORA_APP_ID;

const Call = () => {
    const [iState, updateState] = useState(initialState);
    const { channelName, token, calling, micOn, cameraOn, channelLeaveConfModal } = iState;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    useJoin({ appid: APP_ID, channel: channelName, token: token }, calling);

    const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
    const { localCameraTrack } = useLocalCameraTrack(cameraOn);
    usePublish([localMicrophoneTrack, localCameraTrack]);
    const remoteUsers = useRemoteUsers();
    console.log('remoteUsers astrologer', remoteUsers)

    console.log('localMicrophoneTrack', localMicrophoneTrack, 'localCameraTrack', localCameraTrack)

    // Fetch token and channelName
    useEffect(() => {
        dispatch(getCallInfoApi(state?._id)).then((response) => {
            console.log("API Response:", response);
            updateState({
                ...iState,
                token: response?.payload?.data?.agoraToken?.token,
                channelName: response?.payload?.data?.channelName,
            });
        });
    }, []);

    const handleCallLeave = () => {
        const data = {
            bookingId: state?._id,
            status: 'completed'
        }
        dispatch(updateCallStatusApi(data)).then((res) => {
            if (res?.payload?.status === 200) {
                updateState({
                    ...iState,
                    channelLeaveConfModal: false,
                    calling: false,
                })
                navigate('/appointment/completed')
            }
        })
    }

    console.log('iState', iState)
    return (
        <>
            <div className="ChatBody">
                {remoteUsers.map((user) => (
                    <div key={user.uid} style={{ width: "40vw", height: "80vh", margin: "0px auto" }}>
                        <RemoteUser
                            // cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg" 
                            user={user}
                        >
                            <samp className="user-name">{user.uid}</samp>
                        </RemoteUser>
                    </div>
                ))}
                <ul>
                    <li>
                        {
                            micOn ?
                                <a onClick={() => updateState({ ...iState, micOn: !micOn })}>
                                    <img src={require('../../../Assets/images/microphone-2.png')} />
                                </a> :
                                <a onClick={() => updateState({ ...iState, micOn: !micOn })}>
                                    <img src={require('../../../Assets/images/Call Icon.png')} />
                                </a>

                        }

                    </li>
                    <li>
                        {
                            cameraOn ? <a onClick={() => updateState({ ...iState, cameraOn: !cameraOn })}>
                                <img src={require('../../../Assets/images/video.png')} />
                            </a> : <a onClick={() => updateState({ ...iState, cameraOn: !cameraOn })}>
                                <img src={require('../../../Assets/images/Call Icon-1.png')} />
                            </a>
                        }

                    </li>
                    <li className="CallRemove">
                        {
                            calling ? <a onClick={() => updateState({ ...iState, channelLeaveConfModal: true })}>
                                <img src={require('../../../Assets/images/call-remove.png')} />
                            </a> : <a style={{ color: 'white' }} onClick={() => updateState({ ...iState, calling: true })}>
                                Join
                            </a>
                        }

                    </li>
                </ul>
                {localMicrophoneTrack && localCameraTrack ? (
                    <div
                        style={{ borderRadius: '10px', overflow: 'hidden', position: 'absolute', bottom: '50px', right: '50px', width: '150px', height: '150px' }}
                    >
                        <LocalUser
                            audioTrack={localMicrophoneTrack}
                            cameraOn={cameraOn}
                            micOn={micOn}
                            videoTrack={localCameraTrack}
                            cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                        >
                            <samp className="user-name">You</samp>
                        </LocalUser>
                    </div>
                ) : (
                    "Please Join"
                )}

            </div>
            <Modal
                show={channelLeaveConfModal}
                className="ModalBox">
                <Modal.Body>
                    <div class="LogOutModalArea Delete">
                        {/* <!-- <span><img src="images/logout-icon.png" /></span> --> */}
                        <h5>Leave Call Confirmation</h5>
                        <p>Are you sure you want to leave this call ?</p>
                        <div class="Buttons TwoButtons">
                            <a type="button" class="Button" onClick={() => updateState({ ...iState, channelLeaveConfModal: false })}>No</a>
                            <a type="button" class="Button Red" onClick={handleCallLeave}>Yes</a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Call;
