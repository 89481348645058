import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import SideNav from '../sidenav/Sidenav'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateReportApi, getUserDetailApi } from '../../../Redux/Slices/Appointments/GetUserDetailToGenrateKundli'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const initialState = {
    additionalTips: '',
    questions: [],
    answer: [],
}
const GenrateReport = () => {
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState)
    const dispatch = useDispatch()
    const { state } = useLocation()
    console.log('state', state)
    const { additionalTips, questions, answer } = iState
    const planetArray = [
        {
            planet_name: "Sun",
            planet_mantra: "Om Suryaya Namaha",
            planet_god_mantra: "Om Hreem Kleem Hum Matangyai Phat Svahaà",
            donate: "Wheat, Salt, Jaggery",
            puja: "Matangi Devi Puja & Havan",
            gemstone: "Ruby (Manik)",
            wearing_finger: "Ring Finger",
            wearing_day: "Sunday",
            wearing_metal: "Copper, Silver, Gold",
            rudraksh: "1-Mukhi, 12-Mukhi",
            jadi: "Bela Root"
        },
        {
            planet_name: "Moon",
            planet_mantra: "Om Chandraya Namaha",
            planet_god_mantra: "Hreem Bhuvaneshvaryai Namahà",
            donate: "Rice, Milk, Curd",
            puja: "Bhubaneswari Devi Puja & Havan",
            gemstone: "Pearl (Moti)",
            wearing_finger: "Little Finger",
            wearing_day: "Monday",
            wearing_metal: "Silver, Gold",
            rudraksh: "2-Mukhi, Gouri Shankar",
            jadi: "Kshirakoli Root"
        },
        {
            planet_name: "Mars",
            planet_mantra: "Om Bhaumaya Namaha",
            planet_god_mantra: "Om Hleem Baglamukhi Sarvadushtaanaam Vaacham Mukham Padam Stambhay Jihvaam Keelay Buddhim Vinaashaay Hring Om Swaha",
            donate: "Barley, Masoor Daal",
            puja: "Bagalamukhi Devi Puja & Havan",
            gemstone: "Red Coral (Moonga)",
            wearing_finger: "Ring Finger",
            wearing_day: "Tuesday",
            wearing_metal: "Silver, Gold",
            rudraksh: "3-Mukhi",
            jadi: "Anantmool Roots"
        },
        {
            planet_name: "Mercury",
            planet_mantra: "Om Budhhaya Namaha",
            planet_god_mantra: "Hreem Shreem Kleem Tripuramadane Sarvashubham Sadhaya Svahaà",
            donate: "Millet, Green Moong Daal",
            puja: "Sodashi Devi Puja & Havan",
            gemstone: "Emerald (Panna)",
            wearing_finger: "Little Finger",
            wearing_day: "Wednesday",
            wearing_metal: "Silver, Gold",
            rudraksh: "4-Mukhi",
            jadi: "Buddha Darak"
        },
        {
            planet_name: "Jupiter",
            planet_mantra: "Om Gurabe Namaha",
            planet_god_mantra: "Om Treem Hreem, Hrum, Hreem, Hum Phatà",
            donate: "Turmeric, Saffron, Toor Dal",
            puja: "Maa Tara Puja & Havan",
            gemstone: "Yellow Sapphire (Pukhraj)",
            wearing_finger: "Index Finger",
            wearing_day: "Thursday",
            wearing_metal: "Silver, Gold",
            rudraksh: "5-Mukhi",
            jadi: "Haldi Root"
        },
        {
            planet_name: "Venus",
            planet_mantra: "Om Shukraya Namaha",
            planet_god_mantra: "Namah Kamalavasinyai Svaha",
            donate: "Oat, Rice, Sugar",
            puja: "Kamala Devi Puja & Havan",
            gemstone: "Diamond (Heera)",
            wearing_finger: "Middle Finger Or Ring Finger",
            wearing_day: "Friday",
            wearing_metal: "Silver, Platinum",
            rudraksh: "6-Mukhi, 13-Mukhi",
            jadi: "Masaparni Root"
        },
        {
            planet_name: "Saturn",
            planet_mantra: "Om Sam Shanischaraya Namaha",
            planet_god_mantra: "Om Kreem Hrum Hreem Hum Phata",
            donate: "Black Mustard, Black Urad",
            puja: "Dakhina Kali Puja & Havan",
            gemstone: "Blue Sapphire (Neelam)",
            wearing_finger: "Middle Finger",
            wearing_day: "Saturday",
            wearing_metal: "Silver, Iron",
            rudraksh: "7-Mukhi, 14-Mukhi",
            jadi: "Badiamla Root"
        },
        {
            planet_name: "Rahu",
            planet_mantra: "Om Rahabe Namaha",
            planet_god_mantra: "Hreem Kleem Shreem Aim Hum Phatà",
            donate: "Sesame Oil, Mustard Oil",
            puja: "Chinnamasta Puja & Havan",
            gemstone: "Hessonite (Gomedh)",
            wearing_finger: "Middle Finger",
            wearing_day: "Saturday",
            wearing_metal: "Silver",
            rudraksh: "8-Mukhi",
            jadi: "Sweta Chandan Root"
        },
        {
            planet_name: "Ketu",
            planet_mantra: "Om Ketabe Namaha",
            planet_god_mantra: "Om Dhum Dhum Dhumavati Devyai Svahaà",
            donate: "Carrot, Potato, Peanuts",
            puja: "Dhumavati Puja & Havan",
            gemstone: "Cat's Eye (Lahsuniya)",
            wearing_finger: "Ring Finger",
            wearing_day: "Saturday",
            wearing_metal: "Silver",
            rudraksh: "9-Mukhi",
            jadi: "Ashwagandha Root"
        }
    ];
    const [show, setShow] = useState(false)
    let [services, setServices] = useState([])


    const handleChangeNormal = (e) => {
        const { name, value } = e.target
        updateState({ ...iState, [name]: value })
    }

    const handleChange = (e, item) => {
        console.log('item', item)
        if (e.target.checked) {
            console.log('checked', e.target.checked)
            setServices(prevState => [...prevState, item?.planet_name])
        } else {
            console.log('unchecked', e.target.checked)
            setServices(prevState => prevState.filter(service => service !== item?.planet_name))
        }
    }

    const handleRemove = (planet) => {
        console.log('item', planet)
        const filteredServices = services?.filter((item) => {
            console.log(item, planet)
            return item !== planet
        })
        setServices(filteredServices)
    }

    let planetDetailToDisplay = []

    for (let i = 0; i < services.length; i++) {
        for (let j = 0; j < planetArray.length; j++) {
            if (planetArray[j].planet_name === services[i]) {
                planetDetailToDisplay.push(planetArray[j])
            }
        }
    }

    const handleChangeForAnswer = (e, index) => {
        const { name, value } = e.target
        answer[index] = value
        console.log('name', name, 'value', value, 'answer', index, 'answer[index]', answer[index])
        updateState({
            ...iState,
            answer: answer
        })
    }

    let quesAns = []
    for (let i = 0; i < questions?.length; i++) {
        quesAns.push({ question: questions[i], answer: answer[i] })
    }


    console.log('question', questions, 'answer', answer, 'service', services)

    useEffect(() => {
        if (state?._id) {
            dispatch(getUserDetailApi(state?._id)).then((res) => {
                if (res?.payload?.status === 200) {
                    console.log('Response from generate detail', res)
                    updateState({
                        ...iState,
                        questions: res?.payload?.data?.[0]?.basicDetails?.question //payload.data[0].basicDetails.question
                    })
                }
            })
        }
    }, [])

    const handleGenrateReport = () => {
        const data = {
            bookingId: state?._id,
            quesAns: quesAns,
            planets: services,
            remedyDetails: planetDetailToDisplay?.map((item) => {
                return {
                    "name": item?.planet_name,
                    "planetMantra": item.planet_mantra,
                    "planetGodMantra": item?.planet_god_mantra,
                    "gemstone": item?.gemstone,
                    "wearingFinger": item?.wearing_finger,
                    "metal": item?.wearing_metal
                }
            }),
            additionalTip: additionalTips
        }
        dispatch(generateReportApi(data)).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('Response from generate report', res)
                toast.success('Report generated successfully', {
                    position: "top-right",
                    autoClose: 1000,
                })
                updateState(initialState)
                navigate(-1)
            }
        })
    }

    return (
        <div>
            <Header />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Entry Report Details</h4>
                        <div className="BackArrow">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="BuildingArea">
                        <div className="BuildingPackage QuesAns">
                            <h4>Interpretation &amp; Observation Readings</h4>
                            <p>
                                <span>Interpretation and observations</span>
                            </p>
                        </div>
                        <div className="BuildingPackage QuesAns">
                            <h4>Questions &amp; Answers</h4>
                            {
                                questions?.map((item, index) => {
                                    return (
                                        <p>
                                            <label>Ques {index + 1} : {item}</label>
                                            <input className='form-control' name='answer' value={answer[index]} onChange={(e) => handleChangeForAnswer(e, index)} />
                                        </p>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="TitleBox">
                        <h4 className="Title">Remedy Details</h4>
                    </div>
                    <div className="BuildingArea">
                        <div className="row">
                            <div className="col-lg-6">
                                <div
                                    className="DetailsArea"
                                    style={{ backgroundColor: "#fff", flexDirection: "column" }}
                                >
                                    <div className="SelectGemstone">
                                        {/* <div className="form-group">
                                            <label>Select Gemstone (s) :</label>
                                            <select className="form-control">
                                                <option>--select--</option>
                                                <option>Gemstone 1</option>
                                            </select>
                                        </div> */}
                                        {/* <TestMultiSelect /> */}

                                        <div style={{ cursor: 'pointer' }} className="form-group mb-0"><label>Planets</label>
                                            <div className='form-control' onClick={() => setShow(!show)} style={{ cursor: 'pointer' }}>
                                                <label>Select Planet</label>
                                                <i class={show ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-down rotate"}></i>
                                            </div>
                                        </div>
                                        {
                                            show ?
                                                <div className='CustomSelectNew'>
                                                    <ul style={{ height: '300px', overflowY: 'scroll', display: 'inline-block' }}>
                                                        {planetArray.map((item, index) => (
                                                            <li key={item} style={{
                                                                display: "flex", justifyContent: 'space-between', width: '25%',
                                                                width: '25%',
                                                                minWidth: '225px',
                                                                background: '#f1f2f4',
                                                                padding: '10px',
                                                                borderRadius: "5px",
                                                                borderBottom: '1px solid #ddd'
                                                            }}>
                                                                <label htmlFor={item} style={{ fontSize: '16px' }} >{item?.planet_name}</label>
                                                                {/* <input type="checkbox" name={item?.planet_name} value={item?.planet_name} onChange={(e) => handleChange(e, item)} checked={services?.includes(item?.planet_name)} /> */}
                                                                <label className='CheckBox'>
                                                                    <input type='checkbox' name={item?.planet_name} value={item?.planet_name} onChange={(e) => handleChange(e, item)} checked={services?.includes(item?.planet_name)} />
                                                                    <span className='checkmark'></span>
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div> : null
                                        }
                                        <div className='GemStonesArea mt-4'>
                                            <ul>
                                                {
                                                    services.length > 0 && services?.map((item) => {
                                                        return (

                                                            <li>{item}
                                                                <span onClick={() => { handleRemove(item) }}><i class="fa-solid fa-xmark"></i></span>
                                                            </li>


                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="DetailsContent">
                                        {/* <article>
                                            <div className="GemStonesArea">
                                                <ul>
                                                    <li>
                                                        Gemstones 1{" "}
                                                        <span>
                                                            <i className="fa-solid fa-xmark" />
                                                        </span>
                                                    </li>
                                                    <li>
                                                        Gemstones 2{" "}
                                                        <span>
                                                            <i className="fa-solid fa-xmark" />
                                                        </span>
                                                    </li>
                                                    <li>
                                                        Gemstones 3{" "}
                                                        <span>
                                                            <i className="fa-solid fa-xmark" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h3 className="MantarUsage">
                                                Write how to use the selected gemstones
                                            </h3>
                                        </article> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="DetailsArea"
                                    style={{ backgroundColor: "#fff", flexDirection: "column" }}
                                >
                                    <div className="SelectGemstone">
                                        <div className="form-group">
                                            <label>Selected Planet (s)</label>
                                        </div>
                                    </div>
                                    <div className="DetailsContent">
                                        <article>
                                            <div className="GemStonesArea">
                                                {/* <ul>
                                                    <li>
                                                        Mantra 1{" "}
                                                        <span>
                                                            <i className="fa-solid fa-xmark" />
                                                        </span>
                                                    </li>
                                                    <li>
                                                        Mantra 2{" "}
                                                        <span>
                                                            <i className="fa-solid fa-xmark" />
                                                        </span>
                                                    </li>
                                                    <li>
                                                        Mantra 3{" "}
                                                        <span>
                                                            <i className="fa-solid fa-xmark" />
                                                        </span>
                                                    </li>
                                                </ul> */}
                                                {
                                                    planetDetailToDisplay?.map((item) => {
                                                        return (
                                                            <div style={{ border: '1px solid rgba(255, 144, 0, 1)', margin: '5px', borderRadius: '10px', padding: '5px', background: 'rgba(255, 144, 0, 1)' }}>
                                                                <li><label style={{ fontWeight: '500' }}>Planet Name</label> - <label>{item?.planet_name}</label></li>
                                                                <li><label style={{ fontWeight: '500' }}>Planet Mantra</label> - <label style={{ wordWrap: 'normal' }}>{item?.planet_mantra}</label></li>
                                                                <li><label style={{ fontWeight: '500' }}>Planet God Mantra</label> - <label>{item?.planet_god_mantra}</label></li>
                                                                <li><label style={{ fontWeight: '500' }}>Gemstone</label> - <label>{item?.gemstone}</label></li>
                                                                <li><label style={{ fontWeight: '500' }}>Wearing finger</label> - <label>{item?.wearing_finger}</label></li>
                                                                <li><label style={{ fontWeight: '500' }}>Metal</label> - <label>{item?.wearing_metal}</label></li>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="BuildingPackage QuesAns mt-3">
                            <h4>Additional Tips</h4>
                            <p>
                                <input
                                    name='additionalTips'
                                    value={additionalTips}
                                    onChange={handleChangeNormal}
                                    className='form-group'
                                />
                            </p>
                        </div>
                    </div>
                    <a className="Button" onClick={handleGenrateReport}>
                        Generate Report
                    </a>
                </div>
            </div>
        </div >
    )
}

export default GenrateReport